import React, { useState, useEffect, useContext } from "react";
import DashboardContext, { DashboardContextProvider } from "./DashboardContext";
import './page.css';
import { SideMenuOptions } from './DashBoardUtils';
import { signOut, onAuthStateChanged } from "firebase/auth";
import FAQ from './Components/faq/faq';
import SideMenu from './Components/SideMenu/SideMenu';
import Profile from "./Components/Profile/Profile";
// Components
import Home from './Components/Views/Home/Home';
import Notes from "./Components/Views/Notes/Notes";
import LecturePlayBack from "./Components/Views/LecturePlayback/LecturePlayBack";
import StudyWithOther from "./Components/Views/StudyWithOther/StudyWithOther";
import Collab from "./Components/Views/Collab/Collab";
import AITools from "./Components/Views/AITools/AITools";
import Settings from "./Components/Settings/Settings";

const DashbboardComponent = () => {
    const { userData } = useContext(DashboardContext);
    const [ showSettings, setShowSetting ] = useState(false);
    const appTitle = true == true ? "Auessey." : "------";
    const [ selectedItem , setSelectedItem ]  = useState<SideMenuOptions>(SideMenuOptions.Home);

    return (
        <div className="dashboard-page-container dashboard">
            <div className="dashboard-side-menu">
                {/* @ts-ignore */}
                <Profile appTitle={ appTitle } firstName={ `${ userData.first_name ? userData.first_name : "" }` } lastName={ `${ userData.last_name ? userData.last_name : ""}` } />
                <SideMenu selectedItem={ selectedItem } setSelectedItem={ setSelectedItem } setShowSetting={ setShowSetting }/>
            </div>
            {/* Content View for Selected Side Items */}
            <div className="dashboard-content-view">
                {/* This Should be Exhaustive so don't worry about unintended renders */}
                { ( selectedItem == SideMenuOptions.Home ) &&  <Home/> }
                { ( selectedItem == SideMenuOptions.Notes ) &&  <Notes/> }
                { ( selectedItem == SideMenuOptions.LecturePlayback ) && <LecturePlayBack/> }
                { ( selectedItem == SideMenuOptions.StudyWithOthers ) &&  <StudyWithOther/> }
                { ( selectedItem == SideMenuOptions.Collab ) &&  <Collab/> }
                { ( selectedItem == SideMenuOptions.AI_Tools ) &&  <AITools/> }
            </div>
            { ( selectedItem !== SideMenuOptions.Collab ) && <FAQ/> }
            {/*  */}
            { <Settings showSettingsView={ showSettings } setShowSetting={ setShowSetting }/>}
        </div>
    );
}

export default DashbboardComponent;