import React, { useState, useEffect, useContext } from "react";
import UserNotesContext, { UserNotesProvider } from "../../../Contexts/UserNotesContex";
import Note, { NoteTypeOptions } from '../NoteComponent/Note';
import './UserNotes.css';

const UserNotes = () => {
    const {  } = useContext(UserNotesContext);
    return (
        <>
        <UserNotesProvider>
            <div className="user-notes-container-content">
                <div className="user-notes-scrollable-container">
                    <Note 
                        noteType={ NoteTypeOptions.user }
                    />
                </div>
            </div>
        </UserNotesProvider>
        </>
    );
}; 

export default UserNotes;