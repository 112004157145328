import React, { useState, useEffect, useContext } from "react";
import PlayIcon from './icons/play.png'
import PauseIcon from './icons/pause.png'

import DashboardContext from "../../../DashboardContext";
import './LecturePlayBack.css';
import ColorUI from "../../ColorUI/ColorUI";
// Play Button
// <a href="https://www.flaticon.com/free-icons/play" title="play icons">Play icons created by Freepik - Flaticon</a>
// Pause Button
// <a href="https://www.flaticon.com/free-icons/pause" title="pause icons">Pause icons created by inkubators - Flaticon</a>



// https://ocw.mit.edu/courses/8-04-quantum-physics-i-spring-2013/pages/calendar/
const LecturePlayBack = () => {
    const { userData } = useContext(DashboardContext);
    const [ selectedCourse_ID, setSelectedCourse_ID ] = useState<string | undefined>(undefined);
    const playbackTitle = "Lecture Playback"
    const [ toggleLecturePlayback, setToggleLecturePlayback ] = useState<boolean>(false);
    const [ lectureToPlay, setLectureToPlay ] = useState< undefined | { course: string; lecture: string; professor: string; } >(undefined);

    useEffect(() => {
        setSelectedCourse_ID( cItem => {
            return userData._courses.length ? userData._courses[0]._id : undefined
        })
    }, []);

    const [ playbackItemsList, setPlaybackItemsList ] = useState([
      { course: userData._courses[0].course, lecture: "Introduction to Superposition", professor: "Dr. Richard Feynman" },
      { course: userData._courses[0].course, lecture: "Experimental Facts of Life", professor: "Dr. Richard Feynman" },
      { course: userData._courses[0].course, lecture: "The Wave Function", professor: "Dr. Richard Feynman" },
      { course: userData._courses[0].course, lecture: "Expectations, Momentum, and Uncertainty", professor: "Dr. Richard Feynman" },
      { course: userData._courses[0].course, lecture: "Operators and the Schrödinger Equation", professor: "Dr. Richard Feynman" },
      { course: userData._courses[0].course, lecture: "Time Evolution and the Schrödinger Equation", professor: "Dr. Richard Feynman" },

    ]);
    
    return (
        <>
            <div className="lecture-playback-container">
                {/* <div className="playback-title-container">
                    <h1 className="playback-title"> { playbackTitle } </h1>
                </div> */}

                <div className="header-carder">
                    <ColorUI/>
                    <div className="header-title-desciption-container">
                        <h1 className="view-title">{ playbackTitle }</h1>
                        <label style={{ fontSize: '18px'}}>Soak up all the wisdom by listening to the lecture again like a podcast...</label>
                    </div>
                </div>
                <div className="course-section-option-label-container">
                    <h3 className="course-section-option-label">Courses:</h3>
                </div>
                <div className="course-options-container">
                    <div className="course-options-scrollview hideScrollIndicator">
                        <div className="course-options-content-view">
                            {
                                userData._courses.map((course, index) => {
                                    return (
                                        <div 
                                            key={ index }
                                            className="course-option"
                                            onClick={() => {
                                                //
                                                setSelectedCourse_ID(course._id);
                                            }}
                                            style={{
                                                backgroundColor: `${ selectedCourse_ID === course._id ? 'var(--app-blue)' : 'black'}`
                                            }}
                                        >
                                            <label>{ course.course }</label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="playback-list-container hideScrollIndicator">
                    <div className="playback-list">
                        { 
                            playbackItemsList.map((item, index) => {
                                return (
                                    <div 
                                        key={ index }
                                        className="playback-item"
                                        onClick={() => {
                                            setLectureToPlay(l => {
                                                return item;
                                            })
                                            // Show playback popup and load audio data
                                            setToggleLecturePlayback( bool => {
                                                return !bool; // All The Lecture Playback needed data will be set on click also, in a passable function
                                            });
                                        }}
                                    >
                                        <ColorUI/>

                                        <div className="playback-item-content">

                                            <div className="play-icon-container">
                                                <img className="play-icon-img" src={ PlayIcon }/>
                                                <div className="play-overlay"></div>
                                            </div>

                                            <div className="lecture-playback-card">
                                                <div className="lecture-playback-card-container">
                                                    <div className="card-content">
                                                        <h5 className="course-title">{ item.course }</h5>
                                                        <h6 className="lecture-index-title">{ `lecture #${ index + 1 }` }</h6>
                                                        <h2 className="lecture-title">{ item.lecture }</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={`lecture-playback-player-container ${toggleLecturePlayback ? 'active' : ''}`}>
                <div className="lecture-plauback-player">
                    <div className="close-playback-container">
                        <button 
                        className="close-playback-controller-button"
                        onClick={() => {
                            // close player
                            setToggleLecturePlayback( bool => {
                                return !bool;// Animate the closing of the player so that the view dismisses
                            });
                        }}
                        >X</button>
                        {/* Eventually this will just be a collapse so that the user can continue to navigate and listen to the playback of the content */}
                        { lectureToPlay &&
                            <div className="lecture-playback-playback-content">
                                <h3 className="lplybck-course">{ lectureToPlay.course }</h3>
                                <h4 className="lplybck-professor">{ lectureToPlay.professor }</h4>
                                <h1 className="lplybck-lecture">{ lectureToPlay.lecture }</h1>
                            </div>
                            // I'm sorry you don't get to stop me from working, because I'm certain about what I'm doing. I love this world, but I also love labor
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default LecturePlayBack;


// An option to mix it with brown noise and film score music if interested
// Playback speed option
// Play/Pause
// Skip to next lecture
// Play track with simple thumb similar to what is on youtube
// Speech to text : https://towardsdatascience.com/build-a-speech-to-text-web-app-using-node-js-210f8c054d79

/* 

Whisper API cost:
https://openai.com/pricing

Model        Pricing:
-------      ---------
Whisper:     $0.006 / minute (rounded to the nearest second)

To use this requires chunking of data from the onset. Rather than waiting unitl later we'll go ahead and introduce chunking as a default behavior.
It will save money and have better performance. This will be the process of introducing chunking code wise:

We will store data as an object of with refference to segments of the audio blobs. These blobs will be loaded in sequentually:
The blob file will be stored on Firebase Storage
The objects that serves as a pointer of the independent blob files will be stored on firebase

// Lecture transcript will be loaded into a document using OpenAI's whisper before after the lecture session closes and then we'll this way the user doesn't have to wait for a response and we save money by only supplying the document rather than data from openAI everytime a user listens to a lecture again


Lecture PlayBack Object Example: 
{
    lecutreTitle: string,
    courseID: string,
    courseTitle: string,
    professor: string,
    maxBlobIndex: 4,
    blobObj : {
        1 : { 
            blobID : string // ( Will be a UUID)
            extension: string // ( Will be mp3)
        },
        2 : { 
            blobID : string // ( Will be a UUID)
            extension: string // ( Will be mp3)
        },
        3 : { 
            blobID : string // ( Will be a UUID)
            extension: string // ( Will be mp3)
        },
        4 : { 
            blobID : string // ( Will be a UUID)
            extension: string // ( Will be mp3)
        },
    }
}


The Leture is played this way
function playContent() {
    1. Grab The Lecture PlayBack Object from fb firestore
    2. Grab The First Blob file from fb storage
    3. Load and play the blob file
    
    Hook up controlls
}

*/


// For this project I will learn how to build everything right despite the complexity
