import React, { useState, useEffect, useContext } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { storage, db } from "../../../fb/fbSetup";
import DashboardContext from "../../DashboardContext";
import ColorUI from "../ColorUI/ColorUI";
import { getStorage,  ref, uploadBytes} from "firebase/storage";
import './Settings.css';
import Compressor from 'compressorjs';


interface Props {
    showSettingsView: boolean;
    setShowSetting: React.Dispatch<React.SetStateAction<boolean>>
}

const userprofile_storage_path = 'userprofile';

enum FieldOptions {
    NickName = "nickName",
    Bio = "bio",
    Major = "major",
    AllowDMs = "allowDMs",
}

async function updateField(userID:string, field: FieldOptions, data: any) {
    // Updating the field object of the user collection...
    const _userCollection = "_Users";
    let fieldObject = {};
    fieldObject[`${field}`] = data;
    await updateDoc(doc(db, `${_userCollection}/${userID}`), fieldObject)
    .then( sucess => {
        console.log(sucess, "document upload sucess");
    }).catch( err => {
        console.log("document upload error: ", err);
    })
}

const Settings = ( { showSettingsView, setShowSetting }: Props ) => {
    const { userData, profileImageURL } = useContext(DashboardContext);
    function importData() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.png, .jpg, .jpeg'; // "image/*"
        input.multiple = false;
        input.onchange = _ => {
            const file = input.files[0];
            if (!file.type.match(imageMimeType)) {
              return;
            }
            new Compressor(file, {
                quality: 0.075,
                success: (compressedResult) => { 
                    setFile(compressedResult);
                },
            });
        };
        input.click();
    }

    const [ file, setFile ] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                // @ts-ignore
                const { result } =  e.target;
                if (result && !isCancel) {
                    setFileDataURL(result);
                    const dashboardImage = document.querySelector('.user-profile-image');
                    dashboardImage.setAttribute('src', result);
                }
            }
            fileReader.readAsDataURL(file);
            uploadImage(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [ file ]);


    async function uploadImage(file: any) {
        const storageRef = ref(storage, `${userprofile_storage_path}/${userData.userID}.jpg`);
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });
    }

    return (
        <div 
            className="settings-view" 
            style={{ top: showSettingsView ? '0' : '100vw' }}
        >
            
            <div 
                className="hideSettingsView-view" 
                style={ { backgroundColor: showSettingsView ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)' } }
                onClick={() => { setShowSetting(b => { return false; })}}></div>
            <>
                { 
                    showSettingsView &&
                    <div className="content-and-color-ui-view">
                        <ColorUI colonyCount={100}/>
                        <div className="content-view">
                            <div className="content-view-scroll ">
                                <div className="profile-image-user-name">
                                    <div className="new-profile-image-button">
                                        <img className="user-profile-image" src={ fileDataURL != null ? fileDataURL : profileImageURL } onClick={() => { importData(); }}/>
                                        <div className="plus-icon">
                                            <div className="horizontal-plus"></div>
                                            <div className="vertical-plus"></div>
                                        </div>
                                    </div>
                                    <div className="username-container">
                                        <h1>{ `${userData.first_name} ${userData.last_name}`}</h1>
                                    </div>
                                    <button id="saveAllButton" className="save-all-button save-button">Save All</button>
                                </div>

                                <div className="field-options">
                                    {/* Nick Name */}
                                    <div className="custom-name-container">
                                        <label htmlFor="custom-name" className="title">Nickname / Primary Name</label>
                                        <label htmlFor="custom-name" className="description">Add a nickname or a name you would prefer to go by</label>
                                        <input id="custom-name" type={"text"} className="custom-name" value={ userData.nickName ? userData.nickName : ""} />
                                        <button id="customNameSaveButton" className="save-button" onClick={() => {
                                            // @ts-ignore
                                            let customNameVal = document.querySelector('input.custom-name').value;
                                            console.log("customNameVal: ", customNameVal)
                                            updateField(userData.userID, FieldOptions.NickName, customNameVal);
                                        }}>save</button>
                                    </div>

                                    {/* Major */}
                                    <div className="major-field-container">
                                        <label htmlFor="major" className="title">Major</label>
                                        <label htmlFor="major" className="description">Add your major or your double major</label>
                                        <input id="major" type={"text"} className="major" value={ userData.major ? userData.major : ""}/>
                                        <button id="majorSaveButton" className="save-button" onClick={() => {
                                            // @ts-ignore
                                            let customNameVal = document.querySelector('input.major').value;
                                            console.log("customNameVal: ", customNameVal)
                                            updateField(userData.userID, FieldOptions.Major, customNameVal);
                                        }}>save</button>
                                    </div>
                                    {/* Bio */}
                                    <Bio/>
                                    {/* Update Password */}
                                    <div className="update-password-form-container">
                                        <label htmlFor="bio" className="title">Update Your Password</label>
                                        <label className="description">Update your password if you need to, want to, or think it has been compromised</label>
                                        <span className="password-item">
                                            <label htmlFor="pass-old" className="pass-field">Old Password</label>
                                            <input id="pass-old" type="password" className="old-password"/>
                                        </span>
                                        <span className="password-item">
                                            <label htmlFor="pass-new" className="pass-field">New Password</label>
                                            <input id="pass-new" type="password" className="new-password"/>
                                        </span>
                                        <span className="password-item">
                                            <label htmlFor="pass-new-con" className="pass-field">Confirm New Password</label>
                                            <input id="pass-new-con" type="password" className="confirm-new-password"/>
                                        </span>
                                        <button id="passwordSaveButton" className="save-button">save</button>
                                    </div>
                                    {/* Allow For Direct Messages */}
                                    <AllowDM/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        </div>
    );
}

export default Settings;

// I can and will always say no and yes to labor.



interface AllowDMProps {
    triggerSave?: () => void;
}
const AllowDM = ({ triggerSave }:AllowDMProps) => {
    const { userData } = useContext(DashboardContext);
    const [ allowDM, setAllowDM ] = useState(false);// Should be true by default
    
    useEffect(() => {
        // Setting state based on the data
        if (userData && userData.allowDMs) {
            setAllowDM(b => { return userData.allowDMs; });
        }
    }, [userData])

    return (
        <>
            <div className="allow-direct-messages">
                <label htmlFor="allow-dm" className="pass-field title">Allow Direct Messages</label>
                <label htmlFor="allow-dm" className="description">{"Disallow direct message requests and Disallow recieving direct messages at all (even direct message threads that has already begun)"}</label>
                {/* @ts-ignore */}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input 
                        id="allow-dm" 
                        className="direct-message-checkbox" 
                        type={"checkbox"} 
                        checked={ allowDM } 
                        onClick={ _ => {
                            setAllowDM( b => { 
                                updateField(userData.userID, FieldOptions.AllowDMs, !b);
                                return !b; 
                            } );
                        }}/>
                        <h6>{ allowDM ? "Allow" : "Disallow" }</h6>
                </div>
            </div>
        </>
    ); 
}


const Bio = () => {
    const { userData } = useContext(DashboardContext);
    const [ bioText, setBioText ] = useState<string>("");// Should be true by default

    useEffect(() => {
        // Setting state based on the data
        if (userData && userData.bio) {
            setBioText(b => { return userData.bio; });
        }
    }, [])

    return (
        <div className="bio-field-container">
            <label htmlFor="bio" className="title">Bio</label>
            <label htmlFor="bio" className="description">{ "Include a bio about yourself (very optional)" }</label>
            <textarea 
                id="bio" 
                className="bio" 
                value={ bioText }
                onChange={ e => {
                    setBioText(t => {
                        return e.target.value;
                    })
                }}
            />
            <button id="bioSaveButton" className="save-button" onClick={() => {
                // @ts-ignore
                let customNameVal = document.querySelector('textarea.bio').value;
                console.log("customNameVal: ", customNameVal)
                updateField(userData.userID, FieldOptions.Bio, customNameVal);
            }}>save</button>
        </div>
    );
}


// 4fKn7AdvgBPRBFqFXuMR5ecOqez1.jpg
// 190.96 KB
// image/jpeg
// Jun 25, 2023

