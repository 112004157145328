import './page.css'
import Header from '../Components/Home/Header/Header';
import Landing from '../Components/Home/Landing/Landing';
import Content from '../Components/Home/Content/Content';
import Footer from '../Components/Home/Footer/Footer';
import { useState } from 'react';

// If I can find a place that I can call home in my heart, then I can invite others there one day. They too can find peace and joy in the world also. It starts with letting go.

const Home : React.FC = () => {
  const [ scrolled, setScrolled ] = useState(false);
  function handleScroll (e) {
      if (e.currentTarget.scrollTop >= 50) {
          setScrolled(b => { return true; });
      } else {
        setScrolled(b => { return false; });
      }
  };

  return (
      <main className="main" onScroll={ handleScroll }>
        <div className="main-scrollable-container">
          <Header scrolled={ scrolled } boxShadow={ false }/>
          <Landing/>
          <Content/>
          <Footer/>
        </div>
      </main>
  )
};

export default Home;

// Applicable Law

// By visiting this website, you agree that the laws of the United States of America, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between Inlet AI and you, or its business partners and associates.