import React, { createContext, useState } from 'react';
import { db } from '../fb/fbSetup';
import { doc } from "firebase/firestore"; // Adding document refference


// import { db } from '../fb/fbSetup';
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

interface QuestionContextType {
    // Session Stuff
    sessionID : String;
    questionSessionPath : string;
    updateQuestionSessionPath: (ref: string) => void;
    // Other Items
}

const QuestionContext = createContext< QuestionContextType >({
    sessionID : "",
    questionSessionPath : "",
    updateQuestionSessionPath : () => {},
});

export const QuestionContextProvider = ({ children }: { children: React.ReactNode })  => {
    const sessionID = "ABC-123-A";
    const [ questionSessionPath , setQuestionSessionPath ] = useState("");
    
    function updateQuestionSessionPath(ref: string) {
        setQuestionSessionPath(e => { return ref; });
    }

    const contextValue : QuestionContextType = {
        sessionID,
        questionSessionPath,
        updateQuestionSessionPath
    }

    return (
        <QuestionContext.Provider value={contextValue}>
            { children }
        </QuestionContext.Provider>
    )
};

export default QuestionContext;