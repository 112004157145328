import React from "react";
import './Landing.css'
import mainBackgroundImage from '../../../public/test1.jpg';
import GirlHorizontal from './LandingImages/girlH.jpg';
import GirlVerical from './LandingImages/girlVert.jpg';


const Landing = () => {
    const appTitle = true == true ? "Auessey." : "Dummmy Title";
    const purpose = "We make educating more data-driven, collaborative, and easier for all of the different temperaments represented in the classroom."
    let aPurpose = `
      Get Real Time Feedback about pain points during teaching and training, hear from your most quiet of students, and help students get ready for the world through collaboration.
    `;
    return (
              
    <div className="landing-container">
      <div className="landing-background-image-container">
        <img className="landing-background-image" src={GirlHorizontal} alt="Students and Teacher Learning Together"/>
      </div>
      <div className="landing-content-container">

      <div className="landing-ui-image-container">
        <img className="landing-ui-image" src={ GirlVerical } alt="Students and Teacher Learning Together"/>
        <img className="landing-ui-image top" src={ GirlVerical } alt="Students and Teacher Learning Together"/>
      </div>

        <div className="landing-content-conter-v-stack">
          <h1 className="landing-title">{ appTitle }</h1>
          <h3 className="landing-purpose">{ purpose }</h3>
          <button className="landing-action-button" onClick={() => {
            window.location.href = "/contact-us";
          }}>Get In Touch</button>
        </div>
      </div>
      {/* <span className="diagonal-style-slant"></span> */}
    </div>
    );
};

export default Landing;