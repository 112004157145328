import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './globals.css'


// Routing
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// Non-Sign In Options
import Home from './app/Home/page';
import PricingView from './app/Pricing/page';
import SoftwarePage from './app/software/page';
import AboutPage from './app/about/page';
import ContactUsPage from './app/contact-us/page';
import Careers from './app/careers/page';
import Example from './app/Components/vsxChart';


// Sign In Options
import AuthenticationPage from './app/authentication/page';
import DashbboardPage from './app/dashboard/page';
import SessionRoutingComponent from './app/session/page';
import ProfessorPage from './app/professor/page';
import Distributed from './app/Distributed/page';



const router = createBrowserRouter([
  // Non-Sign In Options
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/ex",
    element: <Example width={500} height={500}/>
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/pricing",
    element: <PricingView/>,
  },
  {
    path: "/software",
    element: <SoftwarePage />,
  },
  {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },

  // Sign In Options
  {
    path: "/authentication",
    element: <AuthenticationPage />,
  },
  {
    path: "/dashboard",
    element: <DashbboardPage />,
  },
  {
    path: "/session",
    element: <SessionRoutingComponent />,
  },

  {
    path: "/professor",
    element: <ProfessorPage />,
  },

  { 
    path: "/distributed",
    element: <Distributed/>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
  <link rel="preconnect" href="https://fonts.googleapis.com"/>
  {/* @ts-ignore */}
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Comfortaa:wght@300;400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap" rel="stylesheet"/>
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
  </>
);

reportWebVitals();
