// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfFLPQiu36TSdgmucPASDQCt_S9RWpShw",
  authDomain: "auessey.firebaseapp.com",
  projectId: "auessey",
  storageBucket: "auessey.appspot.com",
  messagingSenderId: "740081259584",
  appId: "1:740081259584:web:ed3ea6c66569d51aac7ba5",
  measurementId: "G-1SPW29DDLS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);


