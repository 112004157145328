import React, { useState, useEffect, useContext } from "react";
import './page.css';
import Header from "../Components/Home/Header/Header";
import Footer from "../Components/Home/Footer/Footer";
import ColorUI from "../dashboard/Components/ColorUI/ColorUI";

const PricingView = () => {
    return (
        <div className="pricingPage-color-container">
            <ColorUI />
            <div className="pricingPage">
                <div className="pricing-page-vstack">
                    <Header scrolled={false} backgroundColor={'white'}/>
                    <PricingContentView/>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default PricingView;







// Content View
interface PricingCard {
    type : PricngType; // Single Use, Big School Small School ETC.
    price : string; //
    pricingPerUnit : string; // Per Semester, Per Class
    description: string; // Call to Action
}

enum PricngType {
    single = "Single Use",
    smallSchool = "Small School Use",
    mediumSchool = "Medium School Use",
    largeSchool = "Large School Use",
}

let PricingOptionsList: PricingCard[] = [
    {
        type: PricngType.single,
        price: "$249",
        pricingPerUnit : "/Per Class /Per Semester",
        description : "This pricing model is primarily for demoing the product or for those who would like to use the tool temporarily."
    },
    {
        type: PricngType.smallSchool,
        price: "$8,000",
        pricingPerUnit : "/Per Semester",
        description : "This pricing model is primarily for small colleges. ~2,000 students."
    },
    {
        type: PricngType.mediumSchool,
        price: "$12,500",
        pricingPerUnit : "/Per Semester",
        description : "This pricing model is primarily for medium size colleges. >2,500 students but < 9,500 students"
    },
    {
        type: PricngType.largeSchool,
        price: "For Pricing Contact Us",
        pricingPerUnit : "",
        description : "This pricing model is primarily for large colleges. >9,500 students."
    },
];

const PricingContentView = () => {
    return (
        <div className="content-view">
            <div className="title-container">
                <h1>Pricing</h1>
            </div>
            <div className="pricing-model-views">
                {
                    PricingOptionsList.map((option, index) => {
                        return <PricingCard key={ index } type={ option.type } price={ option.price } pricingPerUnit={ option.pricingPerUnit } description={ option.description } />
                    })
                }
            </div>
        </div>
    );
}

const PricingCard = ({ type, price, pricingPerUnit, description }:PricingCard) => {
    return (
        <div className="pricing-card">
            <h4 className="pricing-type">{ type }</h4>
            <h3 className="price">{ price }</h3>
            <label className="pricing-per-unit">{ pricingPerUnit }</label>
            <p className="pricing-description">{ description }</p>
            <button className="pricing-single-call-to-action">Check Out</button>
        </div>
    );
}
