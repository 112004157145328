import React from "react";
import { db } from "../fb/fbSetup";
import { doc, setDoc } from "firebase/firestore";
import './page.css'

const ProfessorPage = () => {



    return (
        <div className="professor-dash-board">
            <div className="professor-dashbord-container">
                <h1>Professor Dashboard</h1>
            </div>
        </div>
    );
};

export default ProfessorPage;
/*
    Don't forget that the professor side has to be extraoridary simple:
    After class should be data rich

    Real time performance should be:
    simply start session, press record, and answer questions, get insight aboout pain points, their focus should be teaching.
*/

/*

Dashboard:
- Add Simple UI:
Should be able to recommend which course should be starting a session based on  day time:
Example: wed, friday 10am - 11:15am, when the professors opens up the application they should see the start session for that course started
Courses -> {
    - Start Session
    - Play Back 
    - Lecture
}

*/

