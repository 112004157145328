import React from "react";
import './Profile.css'
import testUser  from '../../../public/testUser.jpeg';

interface Props {
    appTitle : string;
    firstName : string;
    lastName : string;
}

const Profile = ({ appTitle, firstName, lastName }: Props) => {
    return (
        <>
            {/* App Logo & App Name*/}
            <div className='logo-and-app-title'>
                <h1 className='appliction-title'>{ appTitle }</h1>
            </div>
            {/* User Profile & Username */}
            <div className='username-and-userprofile-img-container'>
                <div className='user-profile-img-container'> 
                    <img className="user-profile-image"/>
                </div>
                <h1 className='username-label'>{ `${ firstName } ${ lastName }` }</h1>
            </div>
        </>
    )
}

export default Profile;