import React, { useState, useEffect } from "react";
import SharedNotes from "./SharedNotes/SharedNotes";
import UserNotes from "./UserNotes/UserNotes";
import './Notes.css';

enum SelectedNotesOptions {
    UserNotes,
    SharedNotes
}

const Notes = () => {
    const [ toggleNotesContainer, setToggleNotesContainer ] = useState(false);
    const [ selectedNotes, setSelectedNotes ] = useState<SelectedNotesOptions>(SelectedNotesOptions.UserNotes);
    return (
        <div className={`controller-main-notes-container${ toggleNotesContainer ? ' show' : '' }`}>
            <div className="toggle-notes-container" onClick={() => { setToggleNotesContainer( bool => { return !bool; }) }} >
                <div className="toggle-notes-button-text">
                    <span>N</span>
                    <span>O</span>
                    <span>T</span>
                    <span>E</span>
                    <span>S</span>
                </div>
            </div>
            <div className="notes-controller-option-container">

                {/* User's : */}
                <div className={`notes-options user-notes-button-container${ selectedNotes == SelectedNotesOptions.UserNotes ? ' active' : '' }`}
                    onClick={() => {
                        setSelectedNotes(option => {
                            return SelectedNotesOptions.UserNotes;
                        })
                    }}
                >
                    <h4>Notes</h4>
                </div>
                <div>
                    {/* <label>Default Mode</label> */}
                </div>
                {/* Shared : */}
                {/* <div className={`notes-options shared-user-notes-button-container${ selectedNotes == SelectedNotesOptions.SharedNotes ? ' active' : '' }`}
                    onClick={() => {
                        setSelectedNotes(option => {
                            return SelectedNotesOptions.SharedNotes;
                        })
                    }}
                >
                    <h4>Shared Notes</h4>
                </div> */}
                {/* Settings */}
            </div>
            <div className="user-shared-notes-container">
                <div className={`user-notes-container${ selectedNotes === SelectedNotesOptions.UserNotes ? ' active': ''}`}>
                    <UserNotes/>
                </div>
                {/* <div className={`shared-notes-container${ selectedNotes === SelectedNotesOptions.SharedNotes ? ' active': ''}`}>
                    <SharedNotes/>
                </div> */}
            </div>
        </div>
    );
}

export default Notes;


/*
Notes on the NOTES feature :
  Building the best note taking system:
  - Can help measure student engagment also... The teachers can then use this to cross refference performance... Those who participate in collective intelegence vs user only notes, and no notes at all
  - Best Word Doc/ Google Doc for code
  - In built symbols (Integrals, Pi, e, etc.), fractions
  - Easy short cuts to access all those, and ability to create cutom short cuts for symbols as mentioned above
  - A parapharase of what professor said, and attempt to copy exactly what the proffessor said
  - In built dictionary
  - In Built Chat GPT -> Adding the Chat gpt directly to your notes
  - In Built Search Engine
  - Ability To bring up Notes
  - Tabs, and color coding notes
  - Highlights of all notes and nots should be done with just colored cirle selection in bottom right: yellow, pink, blue, green, orange
  - Ability to create node like structure that you can use to connect ideas
  = Easy numberizing, ease dashing notes, etc
  - Easy erase words or whole lin
  - Good check that only works with tabs, (maybe include grammarly API)
  - It opperates as a side pop out, counts words per minute... Before relieasing produce
  - Each note is written in a box as opposed to being written like a document, this is for clean, easy customization
  // listens for triple click enter or double click enter to post it to the note
  - Before realeasing product attempt to ake notes to a havard lecture or MIT lecture online using it.

  - This same style is done for the shared notes... all of these are done in one large document, and priority is done by which item was added first
  - Allow users to move to a new page
  - Notes are going to be very limited as far as additions on, but for reading notes, the notes should be exceptionlly easy to understand, read use, and offer a screen reader by default
*/
