import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../../fb/fbSetup";
import { setDoc, doc } from "firebase/firestore";
import ColorUI from "../../ColorUI/ColorUI";
import Note, { NoteTypeOptions } from "../../../../Components/Notes/NoteComponent/Note";
import './Notes.css';
import DashboardContext from "../../../DashboardContext";

enum NoteType {
    Custom = "custom",
    Course = "course"
}

enum NoteModeType {
    Default = 'default',
    Math = 'Math',
    Chemistry = 'Chemistry',
    Code = 'code'
}

interface NoteModel {
}

async function CreateNewCustomNote( userID: string ) {
    const uuid = () => Array.from(Array(16)).map(e => Math.floor(Math.random() * 255)
    .toString(16).padStart(2,"0")).join('').match(/.{1,4}/g).join('-');
                    //        col ->  doc ->    col -> doc
    const newNoteDocument = doc(db, `_Users/${userID}/Notes/${ uuid } `);
    await setDoc(newNoteDocument, {
        date: `${ new Date() }` , // For adding on date of creation
        notes : [], // For adding the note objects
    });
};

const Notes = () => {
    const { userData } = useContext(DashboardContext);
    const [ selectedCourse_ID, setSelectedCourse_ID ] = useState<string | undefined>(undefined);
    const [ NoteType, setNoteType ] = useState<NoteTypeOptions>(NoteTypeOptions.user);
    const [ toggleNoteContainer, setToggleNoteContainer ] = useState<boolean>(false);


    const courses = [
        { course: "Physics", id: '1'},
        { course: "Chemistry", id: '2' },
        { course: "Art", id: '3' },
        { course: "Speech", id: '3' },
    ];

    const [ playbackItemsList, setPlaybackItemsList ] = useState([
        { course: userData._courses[0].course, lecture: "Introduction to Superposition", professor: "Dr. Richard Feynman" },
        { course: userData._courses[0].course, lecture: "Experimental Facts of Life", professor: "Dr. Richard Feynman" },
        { course: userData._courses[0].course, lecture: "The Wave Function", professor: "Dr. Richard Feynman" },
        { course: userData._courses[0].course, lecture: "Expectations, Momentum, and Uncertainty", professor: "Dr. Richard Feynman" },
        { course: userData._courses[0].course, lecture: "Operators and the Schrödinger Equation", professor: "Dr. Richard Feynman" },
        { course: userData._courses[0].course, lecture: "Time Evolution and the Schrödinger Equation", professor: "Dr. Richard Feynman" },
  
      ]);

    return (
        <div className="notes-dash-view-conatainer">
            <div className="notes-main-vstack">

                <div className="notes-controls-hstack">
                    <div className="header-carder">
                        <ColorUI/>
                        <div className="header-title-desciption-container">
                            <h1 className="view-title">{ "Notes" }</h1>
                            <label style={{ fontSize: '18px'}}>Soak up all the wisdom by listening to the lecture again like a podcast...</label>
                        </div>
                    </div>
                    <div className="new-note-button">
                        <div className="plus-icon">
                            <div className="horizontal-plus"></div>
                            <div className="vertical-plus"></div>
                        </div>
                        <label>Add Note</label>
                    </div>
                </div>


                <div className="course-section-option-label-container">
                    <h3 className="course-section-option-label">Courses:</h3>
                </div>
                <div className="course-options-container">
                    <div className="course-options-scrollview hideScrollIndicator">
                        <div className="course-options-content-view">
                            {
                                userData._courses.map((course, index) => {
                                    return (
                                        <div 
                                            key={ index }
                                            className="course-option"
                                            onClick={() => {
                                                //
                                                setSelectedCourse_ID(course._id);
                                            }}
                                            style={{
                                                backgroundColor: `${ selectedCourse_ID === course._id ? 'var(--app-blue)' : 'black'}`
                                            }}
                                        >
                                            <label>{ course.course }</label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                

                <div className="notes-list-container hideScrollIndicator">
                    <div className="notes-list">
                        { 
                            playbackItemsList.map((item, index) => {
                                return (
                                    <div 
                                        key={ index }
                                        className="notes-item"
                                        onClick={() => {
                                            // Setting Toggle Note Container to the opposite of it's default state
                                            setToggleNoteContainer( bool => {
                                                return !bool;
                                            })
                                        }}
                                    >
                                        <ColorUI/>

                                        <div className="notes-item-content">
                                            <div className="notes-card">
                                                <div className="notes-card-container">
                                                    <div className="card-content">
                                                        <h5 className="course-title">{ item.course }</h5>
                                                        <h6 className="notes-index-title">{ `lecture #${ index + 1 }` }</h6>
                                                        <h2 className="notes-title">{ item.lecture }</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* notes-item */}
                                        <div className="note-binder-container">
                                            <div className="note-binders"></div>
                                            <div className="note-binders"></div>
                                            <div className="note-binders"></div>
                                            <div className="note-binders"></div>
                                        </div>

                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <div className={ `selected-note-container ${toggleNoteContainer ? 'active' : ''}`}>
                        <button className="close-note-view-button"
                        onClick={() => {
                            // close player
                            setToggleNoteContainer( bool => {
                                return false; // Animates the Dismissal of this note container
                            });
                        }}
                        >X</button>
                    <Note noteType={ NoteType }/>
                </div>
            </div>
        </div>
    );
};

export default Notes;

// If I knew how good things would get for me, how would I act on that knowledge

// /**
//  * @opacity (optional): Between 0 & 1
//  * @colonyCount (optional): Determines the amout of colors
//  */
// interface ColorUIProps {
//     opacity? : number 
//     colonyCount? : number
//     topComponent? : { children: React.ReactNode }
// }

// const ColorUI = ({ opacity, colonyCount, topComponent } : ColorUIProps) => {
//     let counter = colonyCount ? colonyCount : 50;
//     let colors = [];
//     for(let x = 0; x < counter; x++){
//         colors.push(x);
//     }    
//     function getColorNumber() {
//         return Math.floor( Math.random() * 255);
//     }
    
//     return (
//         <div className="colors-ui-container"
//             style={{
//                 opacity: `${ (opacity && opacity <= 1 && opacity >= 0) ? opacity : 0.3 }`,
//             }}
//         >
//             {
//                 colors.map(colors => {
//                     return (
//                         <>
//                             <div
//                                 style={{
//                                     position: 'absolute',
//                                     zIndex : `${ Math.floor( Math.random() * 1000) }`,
//                                     height: `${ Math.floor( Math.random() * 250) }px`,
//                                     width: `${ Math.floor( Math.random() * 250) }px`,
//                                     borderRadius: '50%',
//                                     left: `${ Math.floor( Math.random() * 100) }%`,
//                                     top: `${ Math.floor( Math.random() * 100) }%`,
//                                     backgroundColor: `rgba( ${ getColorNumber() }, ${ getColorNumber() }, ${ getColorNumber() }, ${ Math.floor( Math.random() * 100) } )`
//                                 }}
//                             ></div>
//                             <div className="top-component">
//                                 <>{ topComponent }</>
//                             </div>
//                         </>
//                     )
//                 })
//             }
//         </div>
//     )
// }