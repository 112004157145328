import React, { useState, useEffect } from "react";

const ContactUsPage = () => {
    return (
        <>
            <div>
                <h1>Contact Us Page</h1>
            </div>
        </>
    )
}
export default ContactUsPage;