import React, { useState, useEffect } from "react";
import "./page.css";
// import WorkingPeople from './Images/workingPeople.jpg'
import Header from "../Components/Home/Header/Header";
import Footer from "../Components/Home/Footer/Footer";

const Careers = () => {
    return (
        <div className="CareersPage">
            <div className="content-view">
                <Header scrolled={ false } backgroundColor={ "white" } boxShadow={true}/>

                <div className="page-title-header">
                    <h1>Careers</h1>
                </div>

                {/* <div className="landing-image-container">
                    <img className="landing-image" src={ WorkingPeople } />
                </div> */}

                <div className="content"> 
                    <h1> { "😕 Sorry currently all positions are filled..." } </h1>
                </div>

                <Footer/>
            </div>
        </div>
    )
}
export default Careers;

//https://www.pexels.com/photo/woman-wearing-black-and-red-gingham-sport-shirt-1181471/