"use client"; // This is a client component 
import React, { useState, useEffect, useContext } from "react";
import QuestionContext from "../Contexts/QuestionContext";
import { db, auth, storage } from '../fb/fbSetup';
import { ref, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, updateDoc, setDoc, onSnapshot } from "firebase/firestore";
import './ClassroomFunctionality.css';
import AskQuestions from '../Components/AskQuestions/AskQuestions';
import QuestionItems from './QuestionItems/QuestionItems';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import LineChart from './LineChart/LineChart';
import { randomUUID } from "crypto";
import { blob } from "node:stream/consumers";


export interface QuestionModel {
    from : String,
    userID : String,
    question : String
}

interface Props {
    session? : string;
    courseID? : string;
}

const ClassroomFunctionality = ({ session, courseID }: Props) => {
    const { updateQuestionSessionPath } = useContext(QuestionContext);
    //
    const [ username, setUserName] = useState("");
    const [ userID, setUserID ] = useState(""); 
    //
    const [ questionStatus, setQuestionStatus] = useState(true);
    const [ course, setCourse ] = useState("");
    const [ semester, setSemester ]  = useState("");
    const [ lecture, setLecture ] = useState("");
    const _userCollection = "_Users";
    let coursePath, sessionPath, questions_session_path_doc, painPoint_session_path_doc = ""
    //@ts-ignore
    let session_QuestionsRef : any; 
    //@ts-ignore
    let session_paintPointRef : any;

    useEffect(function(){
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                getUserDoc(uid);
            } else {
              // User is signed out
              window.location.href = '/authentication';
            }
        });
    }, []);

    async function getUserDoc(uid: string) {
        // Call the initalizer Set Up And Listen to Data Loading In
        const ref = `${_userCollection}/${uid}`;              
        const docSnap = await getDoc(doc(db, ref));
        //
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            let userData = docSnap.data();
            const schoolObj = userData._school;
            const courses = userData._courses;
            setUserName(e => { return `${ userData.first_name } ${ userData.last_name }`; });
            setUserID( e => { return `${ uid }`; });

            //
            // To confirm this student is in that class
            let courseIDs = [];
            courses.forEach(course => { courseIDs.push(`${course._id}`); })
            console.log(`courseIDs.includes(${courseIDs}): `, courseIDs.includes(courseID) )
            console.log('courseIDs: ', courseIDs);
            if ( courseIDs.includes(courseID) ) {
                coursePath = `_SCHOOLS/${ schoolObj.id }/_courses/${ courseID }`;
                sessionPath = `_SCHOOLS/${ schoolObj.id }/_courses/${ courseID }/_sessions/${ session }`;
                questions_session_path_doc = `${sessionPath}/questions/questions`
                painPoint_session_path_doc = `${sessionPath}/pain_point_data/pain_point_data`
                //
                updateQuestionSessionPath(questions_session_path_doc);
                //@ts-ignore
                session_QuestionsRef = doc(db, questions_session_path_doc);
                //@ts-ignore
                session_paintPointRef= doc(db, painPoint_session_path_doc);
                //
                getCourseAndSessionInfo(coursePath, sessionPath);
                initializeListeners();
            }
          } else {
            console.log("No such document!");
          }
    }

    async function getCourseAndSessionInfo( courseRef: string, sessionRef: string ) {
        //sessionPath
        const courseSnap = await getDoc(doc(db, courseRef));
        const sessionSnap = await getDoc(doc(db, sessionRef));
        // Course
        if (courseSnap.exists()) {
            const data = courseSnap.data();
            setCourse(data.course);
            setSemester(data.semester);

        } else {
            console.log("No such document!");
        }
        // Session
        if (sessionSnap.exists()) {
            const data = sessionSnap.data();
            setLecture(data.lecture);
        } else {
            console.log("No such document!");
        }
    }

    const [ questions, setQuestions ]= useState<QuestionModel[]>([]);
    function initializeListeners() {
        // @ts-ignore
        onSnapshot(session_QuestionsRef, (doc) => {
            const data = doc.data();
            let questionsList = (data && data.questions && data.questions as QuestionModel[]) ? data.questions as QuestionModel[] : [];//doc as QuestionModel;
            setQuestions( current => {
                return questionsList
            });
            console.log("Questions data: ", doc.data());
        });
        // @ts-ignore
        onSnapshot(session_paintPointRef, (doc) => {
            console.log("Pain Point Data Current data: ", doc.data());
        });
    }


    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Recording Recording
    // Introducing use recorder to enable recording functionality: https://github.com/samhirtarif/react-audio-recorder
    const [ startedRecording, setStartedRecording] = useState(false);
    let Timer = setInterval(function() {}, 10000000000);
    const [ timeCount, setTimeCount ] = useState(0);
    const [ timeStamps, setTimeStamps] = useState<string[]>([]);
    const [ chartData, setChartData] = useState<number[]>([]);
    const {
        startRecording, stopRecording, togglePauseResume,
        recordingBlob, isRecording, isPaused, 
        recordingTime, mediaRecorder
      } = useAudioRecorder();

    //@ts-ignore
    const addAudioElement = (blob) => {
      const url = URL.createObjectURL(blob);
      const audio = document.createElement("audio");
      audio.src = url;
      audio.controls = true;
      document.body.appendChild(audio);
    };

    const addAudioElementTwo = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
    };



    useEffect(() => {
        if (!recordingBlob) return;

        console.log("recordingBlob: ", recordingBlob);
        stopRecording();

        const CopyOfBlob = recordingBlob;
        let contentType = "mp3";
        let splicedBlob = CopyOfBlob.slice(0, Math.floor(recordingBlob.size / 2), contentType);
        addAudioElement(splicedBlob);//recordingBlob);

        let splicedBlobTwo = CopyOfBlob.slice( Math.floor(recordingBlob.size / 2) , recordingBlob.size, contentType);
        addAudioElementTwo(splicedBlobTwo);
        
        //storage
        const storageRef1 = ref(storage, '1.mp3');
        uploadBytes(storageRef1, splicedBlobTwo).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });

        const storageRef2 = ref(storage, '2.mp3');
        uploadBytes(storageRef2, splicedBlobTwo).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });


        const storageRefFull = ref(storage, 'full.mp3');
        uploadBytes(storageRefFull, recordingBlob).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });

      }, [recordingBlob])
      //
      //

    return (
        <div className="main-functionality">
            <div className="course-title-lecture-title">
                <div className="course-title-container">
                    <h2>{ `${course} - ${semester} Semester` }</h2>
                </div>
                <h2 className="course-title-lecture-divider">{ " / " }</h2>
                <div className="lecture-title-container">
                    <h3>{ lecture }</h3>
                </div>

                <div className="session-code-ui">
                    <div className="session-container">
                        <h2 className="session-key-title">Session Key:</h2>
                        <h3 className="session-key">{ session }</h3>
                    </div>
                </div>


            </div>

            <div className="main-functionality-h-stack">
                {/* Session */}

                {/* Questions */}
                <div className="horizontal-container chat-ui">
                    <div className="questions-container">
                        <div className="questions-status">
                            <div className={`unanswered-question-container ${ questionStatus ? "selected" : "" }`}
                                onClick={() => { setQuestionStatus(bool => { return !bool; }) }}
                            >
                                <h4>Unanswered Questions</h4>
                            </div>
                            <div className={`answered-question-container ${ questionStatus == false ? "selected" : "" }`}
                                onClick={() => { setQuestionStatus(bool => { return !bool; }) }}
                            >
                                <h4>Answered Questions</h4>
                            </div>
                        </div>
                        <div className="questions-vstack-item-container">
                            {
                                questions.map( questionObj => {
                                    return <QuestionItems questionText={questionObj.question}/>
                                })
                            }
                        </div>
                        <AskQuestions name={ username } userID={ userID }/>
                    </div>
                </div>
                {/* Recording UI */}
                <div className="horizontal-container recording-ui">
                    <div className="record-button-container">
                        <h4>Begin Recording Lecture</h4>
                        <div className={ `record-botton outer-layer ${ startedRecording ? "start" : "" }`}
                            onClick={() => {
                                if (isRecording == false) {
                                    startRecording();
                                } else {
                                    stopRecording();
                                }

                                // This is setting the recording button to true
                                setStartedRecording(bool => { 
                                    if (bool == false) {
                                        Timer = setInterval(function() {
                                            setTimeCount(val => {
                                                const count = val + 1;
                                                //
                                                setTimeStamps(list => {
                                                    let timeStampList = [ ...list, `${ count }` ];
                                                    // Creating test data
                                                    setChartData( dataList => {
                                                        const randomDataVal = Math.floor(Math.random() * 100);
                                                        const dataItem = randomDataVal > 93 ? Math.floor(Math.random() * 100) : Math.floor(Math.random() * 1);
                                                        let dataItems = [ ...dataList, dataItem ];
                                                        return dataItems;
                                                    })
                                                    //
                                                    return timeStampList;
                                                })
                                                //
                                                return count;
                                            });
                                            console.log(timeCount, timeStamps)
                                        }, 1000);// every 10 seconds
                                    } else {
                                        // @ts-ignore
                                        clearInterval(Timer);
                                    }
                                    return !bool; 
                                }) 


                            }}
                        >
                                  {/* <AudioRecorder
                                   onRecordingComplete={ (blob) => addAudioElement(blob) } 
                                   recorderControls={ recorderControls }/> */}
                            <div className="record-button-inner-layer"></div>
                        </div>
                    </div>
                    <div className="chart-container">
                        <LineChart dataObj={ { timeStamps: timeStamps, data: chartData, counter: timeCount} } />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ClassroomFunctionality;

/* 

Sales Pitch Notes: 
No dumb question becomes a reality

- Que Of Questions that let's all the students know what will be answered and what has been answered
- Also It allows questions to be asked before the class starts
- A UI that can easily get rid of duplicates
- Real Time Confusion Chart "Education Choke Points"
- End of lecture surveys, with the option to write about what cobfused them
- A feature to allow students to agree to meet with the proffessor during office hours together to go over a list of topics they have questions about, and for them to check off rather or not they are comming this would be just to get a head count. Possible Responses:
   @ Yes
   @ Maybe
   @ No
- Can Throw Up Questions for students to answer ( 
    Multiple Choice, 
    Full text, 
    Max-Character long text response (for example 250 character long, or 10 character long answer for equations that should be easy),
    "Custom characters options, like E, PI, Delta, Omega, Chemistry arrow functions characters, Integerals, Derrivative values",
    True / False, 
    Pre-definded Correct answers for multiple choice with a UI after testing is done)
- Top of The Line Lecature Recoring Software for Lectures, includes Cameras, slide-show software, It Should Be Intuitive, should be able to animate images
- Drawable Visulizations for students to get completely, Ipad and tablet app for proffesorst to white board, and produce  slides from for their sketches and equations


// Student Side Mobile/Desktop:
- Easy UI for  the user floating emoji bar to allow the users to best indicate confusion at a point
- Clean and beautiful floating UITextField for easily adding questions?
- A search bar at the tom to search for answers
- Threads for other students to give answers to questions... this would allow for privacy and the user who asked to easily get updates
- Twitter Spaces/Clubhouse like shared talks
Also it would allow for users to easily share links in class of content that helped them a great deal
- A Discord like Chat for students in the class to ask questions to each other. This is only visible to students and TA's this is for outside of the class. Three layer deep seperate threads
- Students Can Collectively produce notes, study guides, and tests, in real time
- They should be able to schedule meetups for study parties
- Built in ChatGPT to ask questions and see if everyone agrees and why
- Can pay for notes, study guides, and tests from previos people who have taken the test, or from any university taking similar class
- Training a GPT to be able to handle and ask more precise questions
- Be able to produce a visual UI to connect concepts
- All in study tool to help one understand concepts all together.
- Study sessions with any where around the world.

- Remind students of the over arching purpose of how what they just leaned fits into the purpose of the course and/or the lecture topic: that can be reminding the professor to meantion it or have the mention it to the students.

*/






/*
    //
    const sessionID = session ? session : undefined; //"ABC-123-A";
    const course = "PHYS 4810 Quantum Mechanics";
    const semester = "Fall";
    const lecture = "Energy Quantization";
    const professor = "Dr. Max Tegmark";
    const university = "Clark Atlanta University";
    const city = "Atlanta";
    const state = "GA";
    const country = "United States of America";
    const country_abbreviation = "USA";
    const sessionPath = `${ schoolCollection }/${ schoolDocument }/${ _SESSIONCollection }/${ sessionID }`
    const questions_session_path_doc = `${sessionPath}/${'questions'}/${'questions'}`
    const painPoint_session_path_doc = `${sessionPath}/${'pain_point_data'}/${'pain_point_data'}`
    //
    const SessionRef = doc(db,sessionPath)//
    const session_QuestionsRef = doc(db, questions_session_path_doc);
    const session_paintPointRef = doc(db, painPoint_session_path_doc);

    async function addSchool() {
        await setDoc(doc(db, "_SCHOOLS", "Clark_Atlanta_University"), {
            university: university,
            city : city,
            state: state,
            country: country,
            country_abbreviation: "USA"
            //.. This will be all the needed an preliminary data about the university
        });
    };

    async function addSession() {
        await setDoc(SessionRef, {
            sessionKey : sessionID,
            course : course,
            lecture: lecture,
            professor: professor,
            semester : semester,
            university: university,
            city : city,
            state: state,
            country: country,
            country_abbreviation : country_abbreviation,
            recording_audio_recording_refference: "",
            //.. This will be all the needed an preliminary data about the university
        });
    }

    async function addQuestionsSessionDoc() {
        await setDoc(session_QuestionsRef, {
            questions : [],// Sends up an empty array until it is nessary to load in further data
        });
    }

    async function updateQuestions() {
        await updateDoc(session_QuestionsRef, {
            "questions" : [ ...questions, 
                {
                    from : "Rabus McCaleb",
                    userID : "12345678910111213",
                    askedWhen : 0,
                    question : "Which of Maxwell's Equations describes the field created by an electric current?",
                    answered : false,
                    answeredWhen : 0
                }
            ]
        });
    }
    
    async function addPainPointSessionDoc() {
        await setDoc(session_paintPointRef, {
            painpoints : [] // Sends Pain Points.. Pain points listens and updates the documnet at time of setup
        })
    }
*/


