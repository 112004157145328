import React, { useState, useEffect } from "react";
import './page.css';
import SessionNotesImage from './Screenshots/session_notes.png';
import Footer from "../Components/Home/Footer/Footer";
import Header from "../Components/Home/Header/Header";
import ColorUI from "../dashboard/Components/ColorUI/ColorUI";

const SoftwarePage = () => {
    const [ scrolled, setScrolled ] = useState(false);
    function handleScroll (e) {
        if (e.currentTarget.scrollTop >= 50) {
            setScrolled(b => { return true; });
        } else {
          setScrolled(b => { return false; });
        }
    };

    return (
        <div className="softwarePage-color-container">
            <ColorUI/>
            <div onScroll={ handleScroll } className="softwarePage">
                <div className="software-page-vstack">
                    <Header scrolled={ false } boxShadow={ false } backgroundColor="rgba(255,255,255, 0.0)"/>
                    <div className="content-view ">
                        <div className="title-container"><h1>Software</h1></div>


                        <div className="example-item">
                            <h2>Notes</h2>
                            <label>Take better in class notes, even for math.</label>
                            <img  className="example-item" src={ SessionNotesImage }/>
                        </div>


                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
};

export default SoftwarePage;