import React, { useState, useEffect } from "react";
import './Content.css'
import ColorUI from "../../../dashboard/Components/ColorUI/ColorUI";

const Content = () => {
    return (
        <div className="content-container">
            <div className="content-info-container">
                <div className="content-semi-circle">
                    <ColorUI colonyCount={ 50 }/>
                </div>
                <div className="content">
                    <h1>Make Education A Great Experience Inside And Outside The Classroom...</h1>
                    <div className="hstack-content">
                        <label className="content-item">Get Real-Time Insights About Class Pain Points</label>
                        <label className="content-item">Hear From Your Most Shy Student</label>
                        <label className="content-item">Help Students and Professors Connect</label>
                        <label className="content-item">Prepare Students For The Work Force By Taking Advantage of <a href="/distributed">Distrubuted Cognition</a></label>
                    </div>
                </div>
            </div>
            {/* <div className="content-container-item"></div> */}
            {/* <div className="content-container-item"></div> */}
            {/* <div className="content-container-item"></div> */}
        </div>
    );
}

export default Content;