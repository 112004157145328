"use client"; // This is a client component 
import React, { useState, useEffect } from "react";
import { auth } from "../fb/fbSetup";
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
 } from "firebase/auth";
import './page.css';
import b_hand_icon from './icons/b_hand.png';
import b_hand_icon_two from './icons/b_hand2.png';
import b_hand_icon_three from './icons/b_hand3.png';

import y_hand_icon from './icons/y_hand.png';
import y_hand_icon_two from './icons/y_hand2.png';
import y_hand_icon_three from './icons/y_hand3.png';

enum AuthOptionTypes {
    none,
    student,
    professor,
    admin,
}

const AuthenticationPage = () => {
    const [ selectedAuthOption, setSelectedAuthOption ] = useState<AuthOptionTypes>(AuthOptionTypes.none);
    useEffect(function(){
        //
        // signOut(auth);
        //
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/auth.user
              const uid = user.uid;
              // ...
              console.log('user: ', user)
              window.location.href = '/dashboard';
            } else {
              // User is signed out
              // ...
            }
        });
    }, [])
    return (
        <div className="user-authentication-page">
            <div className="user-authentication-page-background"></div>
            <div className="user-authentication-story-board">


                <div className="auth-container-left">
                    <AuthFormContainer formFor={selectedAuthOption}/>
                    <div className="auth-diagnol-ui"></div>
                </div>


                <div className="auth-container-right">
                    <div className="auth-right-greetings-container">
                        <h4>Welcome to Auessey,</h4>
                        <h5>Real time data about pain points.</h5>
                        <h5>Hear from your most shy of students.</h5>
                        <h5></h5>
                    </div>
                    <div className="auth-icon-container">
                            <img src={b_hand_icon}/>
                            <img className="y_hand_icon"  src={y_hand_icon_three}/>
                            <img src={b_hand_icon_two}/>

                            <img className="y_hand_icon" src={y_hand_icon}/>
                            <img src={b_hand_icon_three}/>
                            <img className="y_hand_icon" src={y_hand_icon_two}/>
                        </div>
                </div>


            </div>
        </div>
    );
}

export default AuthenticationPage;

interface AuthOptionProps { 
    option: React.Dispatch<React.SetStateAction<AuthOptionTypes>> 
}

const AuthOptions = ( { option } : AuthOptionProps ) => {
    return (
        <div className="auth-options-container">
            <div className="auth-options">
                <div className="auth-greeting">
                    <h3>Select User Type</h3>
                </div>
                {/* Student Sign In */}
                <div className="auth-option-item student auth-student-option-btn-container">
                    <button
                    onClick={() => {
                            option( state => { 
                                return AuthOptionTypes.student
                            });
                        }
                    }
                    >Student</button>
                </div>
                {/* Professor Sign In */}
                <div className="auth-option-item professor auth-student-option-btn-container">
                    <button
                        onClick={() => {
                                option( state => { 
                                    return AuthOptionTypes.professor
                                });
                            }
                        }
                        >Professor</button>
                </div>
                <div className="auth-admin-option auth-admin-option-btn-container">
                    {/* Admin Sign In Will have a completely different page */}
                    <button
                        onClick={() => {
                            option( state => { 
                                return AuthOptionTypes.admin
                            });
                        }
                    }
                    >Admin</button>
                </div>
            </div>
        </div>
    )
}





interface AuthFormProps { 
    formFor: AuthOptionTypes
}

const AuthFormContainer = ( { formFor } : AuthFormProps ) => {
    const [ email, setEmail ] = useState<string>("");
    const [ password, setPassword ] = useState<string>("");
    return (
      <div className="Auth-form-container">
        <div className="Auth-form">
            <div className="Auth-form-content">
                <h4>Auessey</h4>
                <h3 className="Auth-form-title">Sign In</h3>

                <p className="auth-instructions-label">Email and password fields are required.</p>

                <div className="form-group mt-3">
                    <label>Email address</label>
                    <input type="email"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    onChange={ e => {
                        setEmail(val => { return e.target.value; });
                    }}
                    />
                </div>



                <div className="form-group mt-3">
                    <label>Password</label>
                    <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Enter password"
                    onChange={ e => {
                        setPassword(val => { return e.target.value; });
                    }}
                    />
                </div>


                <div className="d-grid gap-2 mt-3">
                    <button type="submit" className="btn btn-primary"
                        onClick={() => {
                            // auth
                            signInWithEmailAndPassword(auth, email, password)
                            .then((userCredential) => {
                              // Signed in 
                              const user = userCredential.user;
                              if (user) {
                                if (formFor === AuthOptionTypes.student && window) {
                                    window.location.href = '/dashboard';
                                } else if (formFor === AuthOptionTypes.professor && window) {
                                    window.location.href = '/professor';
                                }
                              }
                              // ...
                            })
                            .catch((error) => {
                              const errorCode = error.code;
                              const errorMessage = error.message;
                              console.log(errorCode, errorMessage)
                              // ..
                            });
                        }}
                    >
                    Submit
                    </button>
                </div>


                <p className="forgot-password text-right mt-2">
                Forgot <a href="#">password?</a>
                </p>


            </div>
        </div>
      </div>
    )
  }