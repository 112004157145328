import React, { useState, useEffect } from "react";

const AboutPage = () => {
    return (
        <>
            <div>
                <h1>About Us Page</h1>
            </div>
        </>
    )
}

export default AboutPage;