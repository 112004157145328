import React, { useState, useEffect, useContext } from "react";
import DashboardContext from "../../../DashboardContext";
import './Home.css';

const Home = () => {
    const { userData } = useContext(DashboardContext);
    console.log(userData)

    return (
        <div className="home-view">

            <div className="home-header-carder">
                <ColorUI/>
                <div className="header-title-desciption-container">
                    <h1 className="view-title">Home</h1>
                    <label>Jump into a live session...</label>
                </div>
            </div>
            
            <div className="home-view-hstack">
                <div className="courses">
                    <h3 className="courses-container-title">Live Courses:</h3>
                    <div className="courses-container">
                        
                        { 
                        // @ts-ignore
                        userData._courses ?
                        // @ts-ignore
                            userData._courses.map(course => {
                                return (
                                    <div className="course-item"
                                        onClick={() => {
                                            window.location.href = `/session/?session=${ 'ABC-123-A' }&c=${ course._id }`
                                        }}
                                    >
                                        <ColorUI/>
                                        <div className="recording-ui-home"></div>
                                        <div className="course-item-content">
                                            <h4>{course.course}</h4>
                                            <h6>{`${course.semester} Semester`}</h6>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <></>
                        }
                    </div>
                </div>

                <div className="your-activity">
                    <h1 className="view-title">Your Classroom Engagement</h1>
                </div>
            </div>
        </div>
    );
};

export default Home; 


const CourseItem = () => {
    return (
        <div className="courses-container">
            userData._courses
        </div>
    );
}


/**
 * @opacity (optional): Between 0 & 1
 * @colonyCount (optional): Determines the amout of colors
 */
interface ColorUIProps {
    opacity? : number 
    colonyCount? : number
    topComponent? : { children: React.ReactNode }
}

const ColorUI = ({ opacity, colonyCount, topComponent } : ColorUIProps) => {
    let counter = colonyCount ? colonyCount : 50;
    let colors = [];
    for(let x = 0; x < counter; x++){
        colors.push(x);
    }    
    function getColorNumber() {
        return Math.floor( Math.random() * 255);
    }
    
    return (
        <div className="colors-ui-container"
            style={{
                opacity: `${ (opacity && opacity <= 1 && opacity >= 0) ? opacity : 0.3 }`,
            }}
        >
            {
                colors.map(colors => {
                    return (
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex : `${ Math.floor( Math.random() * 1000) }`,
                                    height: `${ Math.floor( Math.random() * 250) }px`,
                                    width: `${ Math.floor( Math.random() * 250) }px`,
                                    borderRadius: '50%',
                                    left: `${ Math.floor( Math.random() * 100) }%`,
                                    top: `${ Math.floor( Math.random() * 100) }%`,
                                    backgroundColor: `rgba( ${ getColorNumber() }, ${ getColorNumber() }, ${ getColorNumber() }, ${ Math.floor( Math.random() * 100) } )`
                                }}
                            ></div>
                            <div className="top-component">
                                <>{ topComponent }</>
                            </div>
                        </>
                    )
                })
            }
        </div>
    )
}