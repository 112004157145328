// "use client"; // This is a client component 
import React, { createContext, useState, useEffect } from 'react';
import { db, auth, storage } from '../fb/fbSetup';
import { doc, getDoc } from "firebase/firestore"; // Adding document refference
import { onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes , getDownloadURL} from 'firebase/storage';


export interface UserDataModel {
    userID?: string;
    first_name? : string;
    last_name? : string;
    _email? : string;
    _courses? : Course[];
    type? : UserType[];
    _school? : School;
    directMessages?: DirectMessageModel[];// Reffernce to all the availble direct messages should be on the user data object so that it can be pulled
    hasUpdatedPassword?: boolean;
    nickName?: string;
    bio?: string;
    major?: string;
    allowDMs?: boolean;
}

export interface DirectMessageModelList {
    directMessages : DirectMessageModel[];
}

export interface DirectMessageModel {
    userID: string;
    first_name? : string;
    last_name? : string;
    messageThreadID? : string
    _coursesInCommmon? : [Course];
}

//     message: [];


enum UserType {
    student = "_student_",
    professor = "_professor_",
    admin = "_admin_",
    parent = "_parent_",
}

interface Course { 
    course: string, 
    semester: string,
    _id: string 
};

interface School {
     id: string, 
     school: string;
}

interface DashboardContextType {
    userData : UserDataModel;
    profileImageURL : string;
    // otherUserIDCache: OtherUserProfileImage[];
    updateOtherUserProfileImage: (otherID: string) => string | undefined;
}

export interface OtherUserProfileImage {
    userID : string;
    url : string;
}

const DashboardContext = createContext<DashboardContextType>({
    userData: {},
    profileImageURL: "",
    // otherUserIDCache : [],
    updateOtherUserProfileImage: undefined
});

const userprofile_storage_path = 'userprofile';


export const DashboardContextProvider = ({ children }: { children: React.ReactNode })  => {
    const [ userData , setUserData ] = useState({});
    const [ profileImageURL , setProfileImageURL ] = useState("");
    const [ userProfileImagesURLsStored , setUserProfileImagesURLsStored] = useState<OtherUserProfileImage[]>([]);

    function updateOtherUserProfileImage(otherID: string): string | undefined {
        let idExists = false;
        let urlInQuestion : string = '';
        // Check if the ID already exists
        userProfileImagesURLsStored.forEach(item => {
            if (item.userID === otherID) {
                idExists = true;
                urlInQuestion = item.url;
            }
        });

        if (idExists) {
            return urlInQuestion;
        } else {
            const storage = getStorage();
            getDownloadURL(ref(storage, `${userprofile_storage_path}/${otherID}.jpg`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                };
                xhr.open('GET', url);
                xhr.send();
                //
                setUserProfileImagesURLsStored(otherUserImgsObj =>  {
                    const otherUserImages = [ ...otherUserImgsObj, { userID : otherID, url : url } ];
                    return otherUserImages;
                });
                return url;
            })
            .catch((error) => {
                return undefined;
            });
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user) {
                const uid = user.uid;
                getUserDoc(uid);
            } else if (window) {
                window.location.href = '/authentication';
            }
        })
    }, [])
    
    async function getUserDoc(uid: string) {
        // Call the initalizer Set Up And Listen to Data Loading In
        const _userCollection = "_Users";
        const ref = `${_userCollection}/${uid}`;              
        const docSnap = await getDoc(doc(db, ref));
        if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserData( obj => { return userData; } );
            getUserProfileImage(userData.userID);
            console.log('userData: ', userData);
          } else {
            console.log("No such document!");
          }
    }


    async function getUserProfileImage(uid: string) {
        const storage = getStorage();
        getDownloadURL(ref(storage, `${userprofile_storage_path}/${uid}.jpg`))
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();

            // Or inserted into an <img> element
            const userProfileImages = document.querySelectorAll('.user-profile-image');
            userProfileImages.forEach( imgElems => {
                imgElems.setAttribute('src', url);
            })
            setProfileImageURL(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    const contextValue : DashboardContextType = {
        userData,
        profileImageURL,
        // userProfileImagesURLsStored,
        updateOtherUserProfileImage,
    }

    return (
        <DashboardContext.Provider value={ contextValue }>
            { children }
        </DashboardContext.Provider>
    )
};

export default DashboardContext;