import React, { useState, useEffect, useContext } from "react";
import DashboardContext from "../../../DashboardContext";
import './StudyWithOther.css';
import { stunServers } from './StunServers';
import { db } from "../../../../fb/fbSetup";
import { collection, doc, setDoc, addDoc } from "firebase/firestore";
import { async } from "@firebase/util";

const StudyWithOther = () => {
    const { userData } = useContext(DashboardContext);
    const servers = {
        iceServers: [
            {
                urls: stunServers,
            }
        ],
        iceCandidatePooleSize: 10
    }

    // https://www.youtube.com/watch?v=WmR9IMUD_CY
    let loocalStream: any;
    let remoteStream: any;
    const configuration = {'iceServers': [
        { 'urls': 'stun:stun.l.google.com:19302' },
        { 'urls': 'stun:stun1.l.google.com:19302' },
        { 'urls': 'stun:stun2.l.google.com:19302' },
    ]}
    let peerConnections = new RTCPeerConnection(configuration);

    async function startAudioChat() {
        loocalStream = await (await navigator.mediaDevices.getUserMedia({ video: false, audio: true}));
        remoteStream = new MediaStream();
        // @ts-ignore
        loocalStream.getTracks().forEach(track => {
            peerConnections.addTrack(track);
        });

        peerConnections.ontrack = (events) => {
            events.streams[0].getTracks().forEach((track) => {
                remoteStream.addTrack(track);
            })
        }

        let audioElement = document.getElementsByTagName('video');
        if (audioElement) {
            // @ts-ignore
            audioElement.srcObject = loocalStream;
        }

        //
        //
        // FB Setup
        const schoolCollection = "_SCHOOLS";
        const schoolDocument = userData._school.id;
        const _chatCollection = "_chat";
        const chatCallPath = `${ schoolCollection }/${ schoolDocument }/${ _chatCollection }/${ '1234' }`
        const chatCallDoc = doc(db, chatCallPath);
        //
        const offerCandidateCollection = `${ chatCallPath }/${'potential_student_callers'}`;
        //
        // Listen to ice candidates
        peerConnections.onicecandidate = async (event) => {
            event.candidate && await addDoc(collection(db, offerCandidateCollection), event.candidate.toJSON())
        }
        //
        // Offer Description
        const offerDescription = await peerConnections.createOffer();
        await peerConnections.setLocalDescription();
        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type
        }
        //
        await setDoc(chatCallDoc, offer);
    }



    return (
        <>
            <h1>Study With Others</h1>
            <div>
                <button
                    onClick={() => {
                        startAudioChat();
                    }}
                >Get Audio Items</button>
                <video id="audio-element"/>
            </div>
        </>
    );
};

export default StudyWithOther;




