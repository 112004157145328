"use client"; // This is a client component 
//
import React, { useState, useEffect, useContext } from "react";
import { db } from "../../fb/fbSetup";
import { collection, doc, getDoc, updateDoc, setDoc, onSnapshot, FieldValue, arrayUnion} from "firebase/firestore";
import QuestionContext from "../../Contexts/QuestionContext";
import './AskQuestions.css';

interface Props {
    name: string,
    userID: string
}

const AskQuestions = ({ name, userID }: Props) => {
    const { questionSessionPath } = useContext(QuestionContext);
    const [ question, setQuestion ] = useState("");
    async function updateQuestions() {
        await updateDoc(doc(db, questionSessionPath), {
            "questions" : arrayUnion( 
                {
                    from : name,
                    userID : userID,
                    question : question
                }
            )
        });
    }

    return (
        <div className="ask-questions-item">
            <div className="ask-questions-container">
                <textarea className="question-text-area" placeholder="Ask A Question" onChange={ e => {  setQuestion( val => { return e.target.value; })  }}></textarea>
                <button className="submit-button" onClick={ () => {
                    let questionTextElem = document.querySelector('.question-text-area');
                    if ( questionTextElem && question != "" ) {
                        updateQuestions();
                        // @ts-ignore
                        questionTextElem.value = "";
                    }
                }}>Ask</button>
            </div>
        </div>
    );
};

export default AskQuestions;