import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

interface Props {
  dataObj : {
    timeStamps: String[];
    data : number[];
    counter : number;
  }

}

//CHART OPTIONS::::: https://technostacks.com/blog/react-chart-libraries/

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Class Shared Choke Points: ',
    },
  },
};

const labels = ['1', 'February', 'March', 'April', 'May', 'June', 'July'];


const LineChart = ({ dataObj}: Props) => {
  console.log("counter in LineChart : ", dataObj.counter);
  console.log("timeStamps in LineChart : ", dataObj.timeStamps);
  console.log("data in LineChart : ", dataObj.data);
  const dataVals = {
    labels: dataObj.timeStamps,
    datasets: [
      {
        label: 'Choke Points',
        data: dataObj.data,//[ 12, 30, 80, 100 ],
        borderColor: '#2196f2',
        backgroundColor: '#2196f2',
      },
    ],
  };

  return <Line options={options} data={dataVals} />;
};

export default LineChart;