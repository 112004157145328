import React from "react";
// import { useRouter, usePathname, useSearchParams, useParams } from "next/navigation";
import { QuestionContextProvider } from "../Contexts/QuestionContext";
import SessionHeader from '../Components/SessionHeader/SessionHeader';
import ClassroomFunctionality from '../Components/ClassroomFunctionality';
import AskQuestions from '../Components/AskQuestions/AskQuestions';
import WallOfConfusion from './Components/WallOfConfusion/WallOfConfusion';
import Notes from '../Components/Notes/Notes';

const SessionRoutingComponent = () => {
    // const parameters = useParams();
    // const parameters = window.location.href.split('/')[1];
    // course: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    const urlParams = new URLSearchParams(window.location.search);
    const courseID = urlParams.get('c');
    const sessionID = urlParams.get('session');

    //
    // @ts-ignore
    // const sessionID = parameters.sessionID;
    return (
            <main className="main session">
                <SessionHeader/>
                <QuestionContextProvider>
                    <ClassroomFunctionality session={ sessionID } courseID={ `${ courseID }` }/>
                </QuestionContextProvider>
                {/* <WallOfConfusion/> */}
                <Notes/>
            </main>
    );
};

export default SessionRoutingComponent;