"use client"; // This is a client component 
import { DashboardContextProvider } from "./DashboardContext";
import DashbboardComponent from "./DashbboardComponent";
import './page.css';
// Solve Pre-rendering issue
import { useEffect, useState } from "react";

const DashbboardPage = () => {
    return (
        <DashboardContextProvider>
                <DashbboardComponent/> 
        </DashboardContextProvider>
    );
}

export default DashbboardPage;


// Should be able to help universities have some insight into who is studying and then they can cross refference that with their grades

/* 
Home / Courses
--------------------------
- Students should be able navigate auessey by coarses
- Students should be able to see the pain points from lectures for entire class
- Students should be able to see lecture wall of questions
- Students should be able to measure their own engagment


Should be able to recommend which course should be starting a session based on  day time:
Example: wed, friday 10am - 11:15am, when the professors opens up the application they should see the start

Lecture Playback
--------------
- Students should be able to listen to the playback of the lecture 
    Controls & UI:
    * playback speed controls
    * Audio Visualization
    * Easy back/ forward 15 sec
    * Easy play/pause
    * Easy Slider to jump to different times

    Other UI:
    * Course
    * Lecture
    * Lecture #
    * Proffessor
    UI of re adding real time values:
    * Speech to text UI
    * Question re-populate in real time as they happened
    * Pain Point Metrics populate
    * Wall of Confusion Items also

Notes
-------
- Students should be able to locate their notes, listen to it via a screen reader
- Students should be able to see and interact with shared notes

Study With Others
----------------
- Students should be able to see new opportunities to meet up to study with their peers
- Students should be able to start or jump into an existing study chat
- Students should be able to collab to produce study guides

Collab
---------------
- Students should be able to chat in the platform for classes

AI Tools
----------
- Students should be able to share questions and response from Chat GPT agents to exclusively ask if this seems right, mostly correct, or is completely wrong.


Setting:
-----------
User Profile Image
Optional Bio

Reporting /  Safety:
------------
should be form: 
drop down: report type: Bug, Issue, Personal Problem, Acessibility, etc.
report messsage
  ( submit button )


Desired Features:
-------------------
should be form:
Title Field:
Feature Description:
  ( submit button )

*/




/*
    Home
    -----
    House Icon: <a href="https://www.flaticon.com/free-icons/home-button" title="home button icons">Home button icons created by Rendyudha - Flaticon</a>



    Notes
    ---------
    Note Pad : <a href="https://www.flaticon.com/free-icons/note" title="note icons">Note icons created by Freepik - Flaticon</a>
    Notes: <a href="https://www.flaticon.com/free-icons/notepad" title="notepad icons">Notepad icons created by metamiseptiana - Flaticon</a>


    Lecture Playback
    -----------------
    Play Pause : <a href="https://www.flaticon.com/free-icons/play-button" title="play button icons">Play button icons created by abdul allib - Flaticon</a>



    Study With Others:
    ---------
    People: <a href="https://www.flaticon.com/free-icons/ui" title="ui icons">Ui icons created by Icon Hubs - Flaticon</a>
    


    Collab
    --------
    Collaborate: <a href="https://www.flaticon.com/free-icons/collaboration" title="collaboration icons">Collaboration icons created by small.smiles - Flaticon</a>
    Collaborate: <a href="https://www.flaticon.com/free-icons/sport-team" title="sport team icons">Sport team icons created by Freepik - Flaticon</a>


    AI Icon:
    ----------
    Robot: <a href="https://www.flaticon.com/free-icons/chatbot" title="chatbot icons">Chatbot icons created by Freepik - Flaticon</a>
    Robot 2: <a href="https://www.flaticon.com/free-icons/chatbot" title="chatbot icons">Chatbot icons created by surang - Flaticon</a>
*/
