import React, { useState, useEffect } from "react";
import './SideMenu.css'
import { auth } from "../../../fb/fbSetup";
import { signOut } from "firebase/auth";
import { SideMenuOptions } from '../../DashBoardUtils';
// Side Menu Icon
import Home_icon from '../../dashboardIcons/home.png';
import Notes_icon from '../../dashboardIcons/notes.png';
import lecturePlayback_icon from '../../dashboardIcons/play-button.png';
import studyWithOthers_icon from '../../dashboardIcons/people.png';
import collab_icon from '../../dashboardIcons/collaborate.png';
import aiTools_icon from '../../dashboardIcons/robotic.png';

interface Props {
    selectedItem : SideMenuOptions;
    setSelectedItem: (value: React.SetStateAction<SideMenuOptions>) => void;
    setShowSetting: React.Dispatch<React.SetStateAction<boolean>>
}

const SideMenu = ({ selectedItem, setSelectedItem, setShowSetting}: Props ) => {
    const reportingAndSafteyTitle = "Reporting / Safety";
    const desiredFeaturesTitle = "Desired Features";
    
    const sideMenuItems = [
        { label: "Home", icon: Home_icon, option: SideMenuOptions.Home },
        { label: "Notes", icon: Notes_icon, option: SideMenuOptions.Notes },
        { label: "Lecture Playback", icon: lecturePlayback_icon, option: SideMenuOptions.LecturePlayback },
        { label: "Study With Others", icon: studyWithOthers_icon, option: SideMenuOptions.StudyWithOthers },
        { label: "Collab", icon: collab_icon, option: SideMenuOptions.Collab },
        // { label: "AI Tools", icon: aiTools_icon, option: SideMenuOptions.AI_Tools },
    ];

    return (
        <div className="dashbord-side-menu-item-container">
            <ul className="dashboard-side-menu-list-conatainer">
                { 
                    sideMenuItems.map( SideMenuItem => {
                        return (
                            <li className={`dashboard-side-menu-item${ selectedItem == SideMenuItem.option ? ' active': ''}`}
                                onClick={() => {
                                    setSelectedItem(item => { return SideMenuItem.option; })
                                }}
                            >
                                <div className="icon-container">
                                    <img alt="A House Icon" src={ SideMenuItem.icon }/>
                                </div>

                                <div className="label-conatainer">
                                    <label>{ SideMenuItem.label }</label>
                                </div>
                                
                                {/* UI Tool */}
                                <div className="style-selected-side-dash"></div>
                            </li>
                        )
                    })
                }
                {/* Desired Features */}
                {/* Reporting */}
                {/* Settings : Settings don't have to be all that sophisticated at the moment they just have to be an MVP of updatable items */}
                <li className="dashboard-side-menu-item defered-items first"  onClick={() => { 
                    setShowSetting(b => {
                        return !b;
                    })
                }} >
                    <div className="label-conatainer">
                        <label>Settings</label>
                    </div>
                </li>

                <li className="dashboard-side-menu-item defered-items"  onClick={() => { }} >
                    <div className="label-conatainer">
                        <label>{ reportingAndSafteyTitle }</label>
                    </div>
                </li>

                <li className="dashboard-side-menu-item defered-items"  onClick={() => { }} >
                    <div className="label-conatainer">
                        <label>{ desiredFeaturesTitle }</label>
                    </div>
                </li>

                <li className="dashboard-side-menu-item defered-items" onClick={() => { signOut(auth); }} >
                    <div className="label-conatainer">
                        <label style={{ color: 'red' }}>Sign Out</label>
                    </div>
                </li>
                

            </ul>
        </div>
    );
}

export default SideMenu;