import React, { useState, useEffect } from "react";
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer-container">
                <div className="footer-container-vert na-loc">
                    <h1 className="footer-comp-name">Auessey.</h1>
                    <label className="footer-comp-loc">Atlanta, GA</label>
                </div>

                <div className="footer-container-vert">
                    <a href="/d">References On Distributed Cognition</a>
                </div>

                <div className="footer-container-vert">
                    <a className="attributions-link">Attribution</a>
                    <span className="support-email"><a href="mailto: support@auessey.com?subject=Feedback&body=Message">support@auessey.com</a></span>
                </div>

                <p className="logo-from">
                    {/* <a href="https://www.flaticon.com/free-icons/brain" title="brain icons">Brain icons created by Freepik - Flaticon</a> */}
                </p>
        </div>
    );
}

export default Footer;