enum SideMenuOptions {
    Home = "Home",
    Notes = "Notes",
    LecturePlayback = "LecturePlayback",
    StudyWithOthers = "StudyWithOthers",
    Collab = "Collab",
    AI_Tools = "AI_Tools",
    Settings = "Settings",
    Report = "Report",
    DesireFeatures = "DesireFeatures",
}



export { SideMenuOptions };