import React, { useState, useEffect } from "react";
import _symcbol_optional_List from './SymbolList';
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { grammarly_clientID } from "../../../GIT_IgonoreItems";
import './Note.css';
import './ModeStyles/MathModeStyles.css'

export enum NoteTypeOptions {
    user,
    shared
}

interface Props {
    noteType : NoteTypeOptions,
    // Needs to also pass in the ref related to notes into the function
}

enum NoteModeType {
    Default = 'default',
    Math = 'Math',
    Chemistry = 'Chemistry',
    Code = 'code'
}

interface NoteModel {
    noteModeType: NoteModeType;
    content: string;
}

//limit over [0 infi] integ [0 12] f(x) :
//
//         12
// limit   ∫ f(x)
//0 -> oo  0

// ∏
// value={ "∆ in x = 3, if y = 3x + 1" }
const Note = ({ noteType }: Props) => {
    const [ noteText, setNoteText ] = useState("");
    const [ ModeType, setModeType ] = useState<NoteModeType>(NoteModeType.Default);
    const [ ephimeralText, setEphimeralText ] = useState("");
    //noteText
    return (
        <div className="note-controller-container">
            <div className="note-controls">
                <div className="note-content-view">
                    <div className="note-content">
                        <div className="emphmeral-box" dangerouslySetInnerHTML={ { __html: ephimeralText } }></div>
                    </div>
                    {/* <label </div>dangerouslySetInnerHTML={ { __html: mathModeNoteText } }
                    >{ mathModeNoteText }</label> */}

                <div className="mode-items-container">
                    <div className="mode-items-v-stack-grid">
                        {/* Default */}
                        <div className={`mode-item ${ ModeType === NoteModeType.Default ? ' active' : '' }`} onClick={() => { setModeType(_=> { return NoteModeType.Default }) }}>
                            <label>Default</label>
                        </div>
                        {/* Chemistry Mode */}
                        <div className={`mode-item ${ ModeType === NoteModeType.Chemistry ? ' active' : '' }`} onClick={() => { setModeType(_=> { return NoteModeType.Chemistry }) }}>
                            <label>Chemistry <br/>Mode</label>
                        </div>
                        {/* Code Mode */}
                        <div className={`mode-item ${ ModeType === NoteModeType.Code ? ' active' : '' }`}  onClick={() => { setModeType(_=> { return NoteModeType.Code }) }}>
                            <label>Code <br/>Mode</label>
                        </div>
                        {/* Math Mode: A mode that turns math notes in real time into math notes by typing in the text area below > same thing for chemistry but slightly altered*/}
                        <div className={`mode-item ${ ModeType === NoteModeType.Math ? ' active' : '' }`}  onClick={() => { setModeType(_=> { return NoteModeType.Math}) }}>
                            <label>Math <br/>Mode</label>
                        </div>
                        {/* Calcultor */}
                        {/* ChatGPT */}
                    </div>
                </div>
                </div>
                <div className="note-text-area-container">
                    <div className="note-controls-buttons-container">
                        <div className="notes-symbol-option-buttons">
                            {
                                _symcbol_optional_List.map((symbol) => {
                                    return <SymbolButtons symbol={ symbol.symbol } symbolName={ symbol.symbolName }  noteType={ noteType }/>;
                                })
                            }
                        </div>
                    </div>
                    <GrammarlyEditorPlugin clientId={ grammarly_clientID }>
                        <textarea
                            id={`${NoteTypeOptions.user === noteType ? "user" : "shared"}-notes-text-area`}
                            placeholder="Take a note..."
                            onChange={ (e) => {
                                let textAreaVal = e.target.value;
                                // Should print the content of the string if I get a truth value
                                console.log("ModeType: ", ModeType);
                                switch (ModeType) {
                                    case NoteModeType.Math:
                                        console.log("ModeType: ", "Math Again");
                                        MathParser_New(textAreaVal, (transformedText) => {
                                            console.log("transformedText: ", transformedText);
                                            setEphimeralText(_=>{ 
                                                return transformedText; 
                                            });// Updating state method in order to be able to store that note
                                        });
                                        break;
                                    case NoteModeType.Chemistry:
                                        ChemistryModeParse(textAreaVal, (transformedText) => {
                                            setEphimeralText(_=>{ 
                                                return transformedText; 
                                            });// Updating state method in order to be able to store that note
                                        });
                                    break;

                                    case NoteModeType.Code:
                                        CodeModeParse(textAreaVal, (transformedText) => {
                                            setEphimeralText(_=>{ 
                                                return transformedText; 
                                            });// Updating state method in order to be able to store that note
                                        });
                                    break;
                                
                                    default:
                                        DefaultModeParse(textAreaVal, (transformedText) => {
                                            setEphimeralText(_=>{ 
                                                return transformedText; 
                                            });// Updating state method in order to be able to store that note
                                        });
                                    break;
                                }
                                setNoteText(val => { 
                                    return textAreaVal; 
                                });// Updating state method in order to be able to store that note
                            }}
                        />
                    </GrammarlyEditorPlugin>
                </div>
            </div>
        </div>
    );
}

// Example text I have been using:       art [[hello x^[[ it is working ]] ]] 


interface SymbolProps { symbol: string; symbolName : string; noteType : NoteTypeOptions; }
const SymbolButtons = ({ symbol, symbolName, noteType }: SymbolProps) => {
    return (
        <button
            className="notes-symbol-buttons"
            name={ symbolName } 
            onClick={ (e) => {
                const id = `${ NoteTypeOptions.user === noteType ? "user" : "shared"}-notes-text-area` 
                const textarea = document.getElementById(id);
                if (textarea) {
                    //@ts-ignore
                    const oldString = textarea.value;
                    console.log("Text Area String : ", oldString);
                    //@ts-ignore
                    textarea.value = `${oldString}${symbol}`;
                }
            }}>{ symbol }</button>
    )
}


// limit over [0 infi] integ [0 12] f(x) :
// limit over [0 -> infi]
//
//         12
// limit   ∫ f(x)
//0 -> oo  0
function DefaultModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Default Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}



enum MathRepresentaionTypes {
    fraction = "fraction",
    exponent = "exponent",
    parenthesis = "parenthesis",
    calcLimit = "calcLimit",
    epsilon = "epsilon",
    integral = "integral",
    root = "root", // Like square roots or a value raised to the 1/3 power or a value raised to the 1/4 power
}

interface exponent { type: string; numerator: string | number; denominator: string | number; }
interface FractionModel { type: string; numerator: string | number; denominator: string | number; }
function MathParser_New(text: string, callback?: (textVal : string) => void ) {
    const mathModeClass = "mathMode";
    let transformedText = text;
    for(let x = 0; x < transformedText.length; x++) {
        let foundTheClosingBracketInQuestion = false;
        // Memory of the previous and next string
        const currentCharacter = transformedText[x];
        const nextCharacter = transformedText[x + 1] ? transformedText[x + 1] : undefined;
        const previous = x - 1 >= 0 ? transformedText[x - 1] : undefined;

        // Default and always true rules: Should be global replacements
        let enter = /\n/g;
        transformedText = transformedText.replace(enter, '<br>');
        // Integral with no upper and lower bound replace
        let integral = "integ[[]]";
        transformedText = transformedText.replace(integral, '<integral style="font-size: 30px;">∫</integral>');

        let infinity = /infint/g;
        transformedText = transformedText.replace(infinity, '∞')

        let rightArrow = /->/g;
        transformedText = transformedText.replace(rightArrow, '→')

        let leftArrow = /<-/g;
        transformedText = transformedText.replace(leftArrow, '←')

        let delta = /delta/g;
        transformedText = transformedText.replace(delta, '∆')

        //
        if(currentCharacter === "[" && nextCharacter === "[" ) {
            // Get Ready For Transformation
            // Look for closing brackets to get insight into the expression
            interface BrackModel {
                firstBracketPosition : number;
                secondBracketPositon: number;
            }
            let listOfNestedBrackets: string[] = [];
            let newIndexOffset = (x + 2);
            let NestedOpeningBrackets: BrackModel[] = [
                // Starting the array out with initial item found.
                // { firstBracketPosition: x, secondBracketPositon: x + 1 },
            ];
            let ClosingBrackets: BrackModel[] = [];

            // Looping on from there to find the closing brackets
            for(let transformationIndex = x; transformationIndex < transformedText.length; transformationIndex++) {
                const closing_currentCharacter = transformedText[transformationIndex];
                const closing_nextCharacter = transformedText[transformationIndex + 1] ? transformedText[transformationIndex + 1] : undefined;
                const closing_previous = transformationIndex - 1 >= 0 ? transformedText[transformationIndex - 1] : undefined;

                console.log(closing_currentCharacter,closing_nextCharacter);
                // Incremeting Opening Bracket Counter
                if (closing_currentCharacter === "[" && closing_nextCharacter === "[") {
                    // Found another opening and closing bracket 
                    // They both should be true because they are both inheriting their index from the same bracket.
                    NestedOpeningBrackets.push(
                        {
                            firstBracketPosition: transformationIndex,
                            secondBracketPositon: transformationIndex + 1,
                        }
                    );

                    console.log('NestedOpeningBrackets: ', NestedOpeningBrackets);
                }
                
                // Has to both closing brackets
                if(closing_currentCharacter === "]" && closing_previous === "]") {
                    console.log('found closing bracket: ', transformationIndex);
                    // Add to count if there was another
                    ClosingBrackets.push(
                        {
                            firstBracketPosition: transformationIndex,
                            secondBracketPositon: transformationIndex + 1,
                        }
                    ); 

                    console.log('ClosingBrackets: ', ClosingBrackets);

                    if (
                        (ClosingBrackets.length === NestedOpeningBrackets.length) &&
                        foundTheClosingBracketInQuestion === false
                        // || (NestedOpeningBrackets.length === 0 && ClosingBrackets.length === 1)
                    ) {
                        // We have found the end of the expression if this is true
                        //
                        // Looping through the items from the index that caused a search for the closing bracket of an index through the beiging of the closing bracket, and concatinating a string with its content to return the content of that string
                        let content = "";
                        const beginingIndex = x + 2;
                        const endingIndex = ClosingBrackets[ClosingBrackets.length - 1].firstBracketPosition - 2;
                        foundTheClosingBracketInQuestion = true;

                        // Go Back Three to get the transformation
                        const transformationTypePotentialValues = [ 
                            transformedText[x - 1], transformedText[x - 2], transformedText[x - 3],
                            transformedText[x - 4], transformedText[x - 5], transformedText[x - 6], transformedText[x - 7],
                        ]; // Transformation Text should max be 7 characters long
                        const transformationType = ReturnTransformationType(transformationTypePotentialValues);
                        // Cleaning Up Brackets By Checking if they are indeed brackets, by checking a limited space (aka a standard de)
                        for(let contentIndex = beginingIndex; contentIndex <= endingIndex; contentIndex ++) {
                            content = content + transformedText[contentIndex];
                        }
                        let removingBracketsString = transformedText.slice(0, x) + "<sup>" + content + "</sup>" + transformedText.slice(endingIndex + 3) //+ transformedText.slice((x + 1));
                        transformedText = removingBracketsString;
                        console.log('removingOpeningBrackets : ', removingBracketsString)
                        // let removingOpeningBrackets = transformedText.slice(0, (beginingIndex - 2)) + transformedText.slice((beginingIndex - 1));
                        console.log('content: ', content);
                        // `<sup>${ ExponentValue }</sup>`
                        //Escape after finding closing or not finding closing
                        //return;
                    }
                }
            }
        }
    }

    callback(transformedText);
}

enum MathTransformationOptions {
    Integral = "integ", // integral with bounds
    Fraction = "fract", // Fraction
    Exponent = "exp",// Exponent
    Parenthesis = "paran",// Parenthesis
    Limit = "limit", // "limit with bounds"
    Matrix = "matrix", // This is for linerar algerbra and having dimensions and matrix mathematic notation... This should also have be able to be nested in a fraction object and have exponets etc.
}

function ReturnTransformationType( transformationTypePotentialValues: string[]): MathTransformationOptions | undefined {
    let transformationType = "";
    let transFormTypeValue: MathTransformationOptions | undefined = undefined;
    for (let typeIndex = 0; typeIndex < transformationTypePotentialValues.length; typeIndex++) {
        let reverseIndex = ((transformationTypePotentialValues.length - 1) - typeIndex);
        if (transformationTypePotentialValues[reverseIndex] !== "" && transformationTypePotentialValues[reverseIndex] !== undefined) {
            transformationType = transformationType + transformationTypePotentialValues[reverseIndex];
            switch (transformationType) {
                case MathTransformationOptions.Integral:
                        if (
                            transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                            transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                            transformationTypePotentialValues[reverseIndex - 1] !== " "
                        ) {
                            transFormTypeValue = MathTransformationOptions.Integral;
                        }
                    break;
                case MathTransformationOptions.Fraction:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Fraction;
                    }
                break;
                case MathTransformationOptions.Exponent:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Exponent;
                    }
                break;
                case MathTransformationOptions.Parenthesis:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Parenthesis;
                    }
                break;
                case MathTransformationOptions.Limit:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Limit;
                    }
                break;
                case MathTransformationOptions.Matrix:
                    if (
                        transformationTypePotentialValues[reverseIndex - 1] !== "" || 
                        transformationTypePotentialValues[reverseIndex - 1] !== undefined ||
                        transformationTypePotentialValues[reverseIndex - 1] !== " "
                    ) {
                        transFormTypeValue = MathTransformationOptions.Matrix;
                    }
                break;

                default:
                    // Worry Less get high accuracy 
                    // Should not transform the transFormTypeValue because if an option is found it should not be reset to undefined
                break;
            }
        }
    }
    return transFormTypeValue;
}

// v^[[ hey ]] = v^hey ( these are only there to hold the values)
// div[[ e^[[x]] , pi ]] = e^2 / pi

function mathModeClass(addedClass: string): string {
    const mathModeClass = "mathMode";
    return `class="${mathModeClass} ${addedClass}`
}

/* 

A math equation should be a tree that comes to a conclusion. 
That tree should have a series of simple ways to represent it like fractions, exponents, etc.

This path should be a simple set of objects as far as representation goes:

Example:
f(x) = 3x^2x / 4x
To me that should mean:

This could be what the strings have to look like:
f(x) = {
    type : fraction 
    numerator : {
        type: "exponent",
        base: "3x",
        exponent: "2x"
    }
    denominator : {
        type: "string",
        content: "4x"
    }
}


Written like this perhaps
f(x) = exp(-x^2)

f(x) = exp[[
    t: para // type
    c: [[ // content
        t: ex
        bas: -x
        ex: 2 
    ]]
]]
is the Gaussian function


f(x) = exp[[
    t: para
    c: [[
        t: ex
        bas: -x
        ex: 2
    ]]
]]
is the Gaussion function, but not in a parameterized version

f(x) = a * exp[[
    t: para
    c: [[
        t: frac
        num: [[
            t: exp
            bas: x - b
            ex: 2
        ]]
        dem:[[
            t: exp
            bas: 2c
            ex: 2
        ]]
    ]]
]]


I can see this easily, but it will have to find a way to parse this and make it intuitive for the user to write. So things should either be strings or not strings
This means are either strings or they are one of the objects and they should then be parse able.

This means there could be a super object model, where certian keys in the object are queried depending on which "type" of item it is.

There is no way to get around nesting, so either route a super object or not, will have to include a parsing.
These values.


The other option for math mode would be get a string

make a representation of it through an object/ nested object and decode it into what is expected

*/

function MathModeParse(text: string, callback: (textVal : string) => void ) {
    const mathModeClass = "mathMode";
    let transformedText = text;
    transformedText = `<div class="${mathModeClass} oneRowWrap">${ transformedText }</div>`
    const indexOfOpeningRange = transformedText.indexOf('[');
    const indexOfClosingRange = transformedText.indexOf(']');
    let range = transformedText.slice( indexOfOpeningRange + 1, indexOfClosingRange )
    console.log(`${range} ${indexOfOpeningRange} ${indexOfClosingRange}`)


    transformedText = `${transformedText}`;
    // Perform Code Transform
    //..
    //
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>')

    // if no <br> flex with wrapping to next line
    // or flex with wrapping to next line until <br> and flex wrapping in between all <br>'s
    let integral = /integ/g;
    transformedText = transformedText.replace(integral, '<integral style="font-size: 30px;">∫</integral>')

    let infinity = /infint/g;
    transformedText = transformedText.replace(infinity, '∞')

    let rightArrow = /->/g;
    transformedText = transformedText.replace(rightArrow, '→')

    let leftArrow = /<-/g;
    transformedText = transformedText.replace(leftArrow, '←')

    let delta = /delta/g;
    transformedText = transformedText.replace(delta, '∆')
    
    let limitLowerBound: string | number = 0;
    let limitUpperBound: string | number = 'n';
    const limitText = `
    <span class="${mathModeClass} cacl-limit">
        <span style="font-size:20px;">lim</span>
        <span>${limitLowerBound} -> ${limitUpperBound}</span>
    </span>
    `
    if (transformedText.includes("calcLimit[{")) {
        const result = ('MyLongString:StringIWant;'.split(":")[1] ||"").split(";")[0]
        //
    }  
    let limit = /calcLimit/g;
    transformedText = transformedText.replace(limit, limitText)

    if (transformedText.includes("fraction[{")) {
        // const result = ('MyLongString:StringIWant;'.split(",")[1] ||"").split(";")[0]
        //
    }  
    let frac_numerator: string | number = '(x - b)^{{2}}';
    let frac_denominator: string | number = '2c^{{2}}';
    let fractionElem = `
        <span class="${mathModeClass} math-fraction">
            <numerator>${frac_numerator}</numerator>
            <denominator>${frac_denominator}</denominator>
        </span>
    `    
    let fraction = /frac/g;
    transformedText = transformedText.replace(fraction, fractionElem)
    
    // transformedText = transformedText.replace(/over/i, )
    const exponent_symbol_location = transformedText.indexOf('^{');
    if (transformedText.includes('^{') && exponent_symbol_location) {
        const closingBrackets = "}"
        const exponentClosingBrackets = transformedText.indexOf(closingBrackets);
        
        const splittableText = transformedText.slice( exponent_symbol_location + 2, exponentClosingBrackets);
        const ExponentValue = splittableText.split('')[0];

        transformedText = transformedText.slice(exponent_symbol_location, exponent_symbol_location + 1);
        console.log("After transformedText.slice(exponent_symbol_location, exponent_symbol_location + 1): ", transformedText);
        let closingBracketOffeset = exponentClosingBrackets + 2; // Comes from removing the other text
        transformedText = transformedText.slice(closingBracketOffeset, closingBracketOffeset + 1);
        console.log("After transformedText.slice(closingBracketOffeset, closingBracketOffeset + 1): ", transformedText);


        if (ExponentValue) {
            // Removing integral sign
            transformedText = transformedText.slice( 0 , exponent_symbol_location) + transformedText.slice( exponent_symbol_location + 1 );
            transformedText = transformedText.replace(ExponentValue,`<sup>${ ExponentValue }</sup>` );
        }
        //transformedText = `${transformedText} <sup>${ ExponentValue }</sup>`
    }

    //transformedText = `${transformedText} ${ exponent_symbol_location }`

    callback(transformedText);
}

/*
    The hard thing is to represent P E M D A S with ease in a UI such that it is similar to notes
    It has to map well to how it would be written if it was done through pen, but also be easy and intuitive, and be able to be done in a way that can also easily be represented as an object

    Maybe that is the trick, that it can be done as an object representation each math not can use P E M D A S in an easy way such that it can be a completely different object.

    enum MathRepresentaionTypes {
        fraction = "fraction",
        exponent = "exponent",
        parantheses = "parantheses",
    }
*/

function ChemistryModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Chemistry Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}


function CodeModeParse(text: string, callback: (textVal : string) => void ) {
    let transformedText = text;
    //
    // Code Parsing Code
    let enter = /\n/g;
    transformedText = transformedText.replace(enter, '<br>');
    //
    callback(transformedText);
}



function removeByIndex(str : string , index: number) {
    return str.slice(0,index) + str.slice(index+1);
}

function findIndex() {
    var getIndices = (s, t) => {
        return [...s].flatMap((char, i) => (char === t ? i + 1 : []));
      };
}



export default Note;



// Don't make it harder to work. Find a place of peace and stay there. Life is meant for risks, and people like me are supposed to take them
// Don't run from them embrace them with deep patience. The aim is not to win, but work. This requires a pretty intense kind of focus. That has to be true anywhere.
// It has to be true in your soul, it has to be true in the future, this has to be true as to be your aims. Clear everything out now.
// And love labor 
// Human choice is human choice.
// Keep in mind that this is the path to that beauty and freedom that allows people like you and you to live good lives.



/*
    let exponent_indcies : number[] = [];
    function findAllIndecies(substr: string) {
        exponent_indcies = [];
        for (let x = 0; x < transformedText.length - 1; x++) {
            if (transformedText.charAt(x) == substr) {
                exponent_indcies.push(x);
                console.log(transformedText.charAt(x));
            }
        }
    }
    findAllIndecies(`^`);

    for (let x = 0; x < exponent_indcies.length; x++) {
        const exponent_symbol_location = exponent_indcies[x]; //transformedText.indexOf('^');
        console.log('exponent_symbol_location: ',exponent_symbol_location);

        if (transformedText.includes('^') && exponent_symbol_location) {
            const splittableText = transformedText.slice( exponent_symbol_location + 1, transformedText.length - 1 );
            console.log('splittableText: ',splittableText);

            const ExponentValue = splittableText.split('')[0];
            console.log('ExponentValue: ',ExponentValue);

            if (ExponentValue) {
                console.log(exponent_indcies);
                // Removing integral sign
                transformedText = transformedText.slice( 0 , exponent_symbol_location) + transformedText.slice( exponent_symbol_location + 1 );
                transformedText = transformedText.replace(ExponentValue,`<sup>${ ExponentValue }</sup>` );
                findAllIndecies(`^`);
            }
            //transformedText = `${transformedText} <sup>${ ExponentValue }</sup>`
        }
    }

*/