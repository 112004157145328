import React, { useState, useEffect } from "react";
import './AITools.css';

const AITools = () => {
    return (
        <>
            <h1>AI Tools</h1>
        </>
    );
};

export default AITools;