
import React, { useState, useEffect } from "react";
import './Header.css'
import logo from '../../../public/temp_logo.png';

interface Props {
    scrolled: boolean;
    backgroundColor?: string;
    boxShadow?: boolean;
}

const Header = ({ scrolled, backgroundColor, boxShadow }: Props) => {
    const appTitle = true == true ? "Auessey." : "------";
    const pricingTitle = true == true ? "Pricing" : "-----";
    const softwareTitle = true == true ? "Software" : "-----";
    const aboutTitle = true == true ? "About" : "-----";
    const contactUsTitle = true == true ? "Contact Us" : "-------, -- --";
    const careersTitle = true == true ? "Careers" : "-------";
    const reportingAndSafteyTitle = true == true ? "Reporting / Safety" :"--------- - ------"
    const desiredFeaturesTitle = true == true ? "Desired Features" : "------- --------";

    function scrollFunc () {
        if (window.scrollY >= 10) {
            const onScrollAnimationItems = document.querySelectorAll('.home-style-scroll');
            onScrollAnimationItems.forEach(elem => {
                // @ts-ignore
                elem.style.color = "white";
            })
        }
    };

    useEffect( () => {
        const onScrollAnimationItems = document.querySelectorAll('.home-style-scroll');
        if( scrolled ) {
            onScrollAnimationItems.forEach(elem => {
                // @ts-ignore
                elem.style.transition = '0.15s ease-in-out';
                // @ts-ignore
                elem.style.color = "white";
            })
        } else {
            onScrollAnimationItems.forEach(elem => {
                // @ts-ignore
                elem.style.transition = '0.15s ease-in-out';
                // @ts-ignore
                elem.style.color = "black";
            })
        }
    }, [ scrolled ]);

    return (
        <div className="home header-container" style={{ backgroundColor: backgroundColor? backgroundColor : ""}}>
            {/* Header Logo and Application Name  */}
            <div className='logo-and-app-title'>
                <h1 className='appliction-title home-style-scroll'
                    onClick={() => {
                        window.location.href = "/"
                    }}
                >{ `${appTitle}` }</h1>
            </div>
            {/* Header Nav Items */}
            <ul className='header-nav-options'>
                <li>
                    <a className="home-style-scroll" href="/pricing">{ pricingTitle }</a>
                </li>
                <li>
                    <a className="home-style-scroll" href="/software">{ softwareTitle }</a>
                </li>
                <li>
                    <a className="home-style-scroll" href="/about">{ aboutTitle }</a>
                </li>
                {/* <li><a href="/contact-us">{ contactUsTitle }</a></li> */}
                <li>
                    <a className="home-style-scroll" href="/careers">{ careersTitle }</a>
                </li>
            </ul>
            {/* Start Header Custom Buttons */}
            {/* <h3 className="header-buttons reporting-saftey">
                <a href="#">{ reportingAndSafteyTitle }</a>
            </h3> */}
            <h3 className="header-buttons desired-features">
                <a href="#">{ desiredFeaturesTitle }</a> 
            </h3>
            <h3 className="header-buttons sign-in-up" style={{ boxShadow: boxShadow === true ? '0 0 8px 2px #0f4a825c' : 'none !important' }}>
                <a href="/authentication">{ "Sign In" }</a> 
            </h3>
            {/* End of Header Buttons */}
        </div>
    )
}

export default Header;