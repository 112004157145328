import React from "react";
import './QuestionItems.css'

interface Props {
    questionText : String
}

const QuestionItems = ({ questionText }: Props) => {
    return (
        // @ts-ignore
        <div className="question-item" htmlFor="checkbox">
            <input
                type="checkbox"
                className="rounded-checkbox"
                id="checkbox"
            />
            <h4>{ questionText }</h4>
        </div>
    )
};

export default QuestionItems;

