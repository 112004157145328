
const _symcbol_optional_List: {
    symbolName: string,
    symbol: string
}[] = [
    // Where I'm getting most of my symbols from https://mathvault.ca/hub/higher-math/math-symbols/calculus-analysis-symbols/
    // https://en.wikipedia.org/wiki/Mathematical_operators_and_symbols_in_Unicode
    // Math
    { symbolName: "Delta", symbol: "∆" },
    { symbolName: "integral", symbol: "∫" },
    { symbolName : `Y Prime`, symbol: `y'` },
    { symbolName : `closed contour / line integral`, symbol: `∮` },
    { symbolName : `double integral`, symbol: `∬` },
    { symbolName : `triple integral`, symbol: `∭` },
    { symbolName : `closed surface integral`, symbol: `∯` },
    { symbolName : `closed volume integral`, symbol: `∰` },
    { symbolName : `imaginary unit i ≡ √-1`, symbol: `i` },
    { symbolName : `Square Root`, symbol: `√` },
    { symbolName : `Gamma`, symbol: `\u03A9` },
    { symbolName : `Capital omega`, symbol: `\u03B3` },
    { symbolName : `Epsilon`, symbol: `\u03B5` },
    { symbolName : `Delta`, symbol: `\u03B4` },
    { symbolName : `Constant e`, symbol: `\u212F` },
    { symbolName : `Partial differential of variable x`, symbol: `\u2202x` },
    { symbolName : `_ asymptotically equal to _`, symbol: `\u223C` },
    { symbolName : ``, symbol: `\u0393` },

    //Chemistry
    { symbolName : `Chemistry Equilibrium`, symbol: `⇌` },
    { symbolName : `Arrow`, symbol: `→` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    { symbolName : `Greater than or equal to`, symbol: `≥` },
    { symbolName : `Less than or equal to`, symbol: ` ≤` },
    { symbolName : `Summation`, symbol: `∑` },
    { symbolName : `Element of`, symbol: `∈` },
    { symbolName : `Degree Celsius`, symbol: `℃` },
    { symbolName : `Degree Fahrenheit`, symbol: `℉` },
    { symbolName : `Infinity`, symbol: `∞` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },
    // { symbolName : ``, symbol: `` },

    // { symbolName: `change in y over the change in x`, symbol: `\frac{dy}{dx}`}    
    // { symbolName : ``, symbol: `` },
];

export default _symcbol_optional_List;

