import React from "react";
import './SessionHeader.css';
import logo from '../../public/temp_logo.png';

const SessionHeader = () => {
  const appTitle = true == true ? "Auessey." : "------";
  const aboutTitle = true == true ? "Courses" : "-----";
  const contactUsTitle = true == true ? "Contact Us" : "--------- --";
  const careersTitle = true == true ? "Feedback" : "-------";
  const reportingAndSafteyTitle = true == true ? "Help" :"--------- - ------"
  const desiredFeaturesTitle = true == true ? "Settings" : "------- --------";
    return (
        <div className="header-container">
          <div className='logo-and-app-title'>
            {/* <div className='logo-container'> <img src={ logo }/> </div> */}
            <h1 className='appliction-title'>{ `${ appTitle }` }</h1>
          </div>
          <ul className='header-nav-options'>
            <li><a> { aboutTitle } </a></li>
            <li><a> { careersTitle } </a></li>
          </ul>
          {/* Header Custom Buttons */}
          <h3 className="header-buttons reporting-saftey">{ reportingAndSafteyTitle }</h3>
          <h3 className="header-buttons desired-features"> { desiredFeaturesTitle } </h3>
        </div>
    )
};

export default SessionHeader;

/*

Help customers with any questions or problems they encounter via email, our support forum, community Slack and any other channels. Our customers are highly skilled DevOps Engineers, you won't be spending your time performing password resets and turning equipment off and back on again
Building CI/CD pipelines to replicate customer issues
Use IaC and CaC to facilitate the rapid build and tear down of test environments
Create resources such as how-to's, documentation, and blog posts to help our customers and the support team solve problems
Be actively involved in the design of new features and other changes - any suggestions that you feel will reduce the support impact will be prioritised highly, and issues that appear in the support queue more than a couple of times will be fixed as a matter of priority
Play a huge part in leading and growing our support capability as our business grows. Have ideas to make your job easier or provide a better customer experience? We want to hear them

*/