"use client"; // This is a client component 
//
import React, { createContext, useState } from 'react';
import { db } from '../fb/fbSetup';
import { collection, doc, getDoc, updateDoc, setDoc, onSnapshot, arrayUnion} from "firebase/firestore";

interface UserNotesType {
    notes: NoteItem[]
}

interface NoteItem {
    content: string;
    highlightColor: string;
    time: string; // Can be unwrapped by any framework
}

const UserNotesContext = createContext<UserNotesType>({
    notes: []
});

export const UserNotesProvider = ({ children }: { children: React.ReactNode })  => {
    const [ noteItems, setNoteItems ] = useState<NoteItem[]>([]);
    // Also I think the table it to high
    // And it is slightly cold in here
    const userID : string = "";
    //
    const sessionID = "ABC-123-A";
    //
    const schoolCollection = "_SCHOOLS";
    const schoolDocument = "Clark_Atlanta_University";
    const _SESSIONCollection = "_SESSION";
    const students_collection = "students";
    const students_document = "students";
    //
    const sessionPath = `${ schoolCollection }/${ schoolDocument }/${ students_collection }/${ students_document }` // From going down that collection path there are two or three things that need to happen 
    //
    //
    async function listenForNoteItemsToChange() {
        // noteItems
    }
    //
    // Add items to the context provider
    // They have to abide by the items being passed into the context provider
    const contextValue : UserNotesType = {
        // @ts-ignore
        noteItems
    }

    return (
        <UserNotesContext.Provider value={contextValue}>
            { children }
        </UserNotesContext.Provider>
    )
};

export default UserNotesContext;


/*
 What do I need for  notes to work as expected?
- note content
- Highlight color
- time
-
*/