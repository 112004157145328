import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../../fb/fbSetup";
import { getDoc, doc, updateDoc, arrayUnion, onSnapshot } from "firebase/firestore";


import imageModeIcon from './Icons/image.png';
import sendIcon from './Icons/send_white.png';
import test1 from './Icons/test.jpg';
import test2 from './Icons/test2.jpg';


import DashboardContext, { UserDataModel , DirectMessageModel } from "../../../DashboardContext";
import './Collab.css';
import ColorUI from "../../ColorUI/ColorUI";

interface CourseModel {
    id: string;
    course: string;
    year: string;
    Dates: { time: string, day: string }[];
    students : { lastName: string, firstName: string, id: string }[];
    professor: string;
    semester: string;
}

const Collab = () => {
    // Anxiety is inauthentic being about a narrive we tell ourselves
    const { userData } = useContext(DashboardContext);
    const [ courses, setCourses ] = useState<CourseModel[]>([])

    async function getCourseDoc() {
        const courseDoc = await getDoc(doc(db, `_SCHOOLS/${ userData._school.id }/_courses/${ userData._courses[0]._id }`));
        if ( courseDoc.exists() ) {
            let courseData = courseDoc.data() as CourseModel;
            setCourses((c) => {
                let coursesList = [ courseData ];
                return coursesList;
            })
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        getCourseDoc();
    }, []);
    // const { userData } = useContext(DashboardContext);
    const DirectMessageOptions: {
        _courses : { course : string }[]
    } = { _courses: [ 
        { course: "Test Item 1" },
        { course: "Test Item 2" },
        { course: "Test Item 3" },
        { course: "Test Item 4" },
        { course: "Test Item 5" },
        { course: "Test Item 6" },
        { course: "Test Item 7" },
    ] };

    const [ selectChatType , setSelectChatType ] = useState<ChatType>(ChatType.CourseThread);// Course Thread By Default
    const [ MessageThreadOption, setMessageThreadOption ] = useState<any | undefined>([]);/*
        This is for later and should relate to courses if course thread is selected
        Or
        It should relate to the avaible direct messages if the direct message option is selected
        
        The array has to be a tuple because what is holding things up at the moment is me not knowing the type
     */

    function changeMessageThread(index: number) {
        if (selectChatType === ChatType.CourseThread) {
        } else if (selectChatType === ChatType.DirectMessage) {
        }
    }
    return (
        <div className="collab">
            <ChatHeader/>
            <div className="collab-course-options-and-chat">
                <SideMenu_ChatCourseOption 
                    ChatOptionType={ selectChatType } 
                    Select={ changeMessageThread } 
                    SetSelectChatType={ setSelectChatType } 
                    CourseData={ userData } 
                    DirectMessageData={ userData.directMessages } 
                />
                <ChatContainer chatType={ selectChatType }/>
                <MessageRequestsAndUsersList courses={ courses }/>
            </div>
        </div>
    );
};
export default Collab;



const ChatHeader = () => {
    return (
        <div className="collab-header">
            <ColorUI opacity={1}/>
            <div className="app-title-and-course">
                <h1 className="appliction-title">Collab</h1>
                <label className="explaination-label">Collaborate with your peers to solve problems and classroom hangups. </label>
            </div>
        </div>
    );
}

enum ChatType {
    CourseThread,
    DirectMessage
}


interface SideMenuProps {
    ChatOptionType: ChatType;
    SetSelectChatType: React.Dispatch<React.SetStateAction<ChatType>>;
    Select: (index: number) => void;
    CourseData?: UserDataModel; // for now we will pass an any down the pipe, but soon I'll have data to pass down... this should just relate to the side menu
    DirectMessageData?: DirectMessageModel[];
}

const SideMenu_ChatCourseOption = ({ ChatOptionType, SetSelectChatType, Select, CourseData, DirectMessageData }: SideMenuProps) => {
    console.log('DirectMessageData: ', DirectMessageData);
    return (
        <div className="collab-sidemenu">
            <ColorUI/>
            <div className="collab-sidemenu-content">
                <div className="side-menu-courses-title-container">
                    <div className="chat-type">
                        <div className={`course-thread-option${ChatOptionType === ChatType.CourseThread ? ' selected': ''}`}
                            onClick={() => {
                                SetSelectChatType(old => {
                                    return ChatType.CourseThread;
                                })
                            }}>
                            <h4 className="side-menu-courses-title"
                            style={{
                                backgroundColor: ChatOptionType === ChatType.CourseThread ? 'var(--app-blue)' : 'transparent',
                                color:  ChatOptionType === ChatType.CourseThread  ? 'white': 'black',
                            }}>Courses Thread</h4>
                        </div>
                        <div className={`direct-message-option${ChatOptionType === ChatType.DirectMessage ? ' selected': ''}`}
                            onClick={() => {
                                SetSelectChatType(old => {
                                    return ChatType.DirectMessage;
                                })
                            }}>
                            <h4 className="side-menu-courses-title"
                                style={{
                                    backgroundColor: ChatOptionType === ChatType.DirectMessage ? 'var(--app-blue)' : 'transparent',
                                    color: ChatOptionType === ChatType.DirectMessage  ? 'white': 'black',
                                }}>Direct Message</h4>
                        </div>
                    </div>
                </div>
                { // UnderStand The Importance
                    ChatOptionType === ChatType.CourseThread ?
                        <SideMenu_CourseOptions ChatOptionType={ChatOptionType} Select={Select} SetSelectChatType={SetSelectChatType} CourseData={ CourseData }/>
                    :
                        <SideMenu_DirectMessagesOptions ChatOptionType={ChatOptionType} Select={Select} SetSelectChatType={SetSelectChatType} DirectMessageData={ DirectMessageData } />
                }
            </div>
        </div>
    );
}



const SideMenu_CourseOptions = ( { ChatOptionType, SetSelectChatType, Select, CourseData }: SideMenuProps ) => {
    return (
        <div className="course-options-container-scrollview">
            <div className="course-options-content-view">
                { CourseData &&
                    // @ts-ignore
                    CourseData._courses.map( (course, index) => {
                        return (
                            <div className="course-item" key={ index }>
                                <label className="course-label">{ `# ${course.course}` }</label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

const SideMenu_DirectMessagesOptions = ( { ChatOptionType, SetSelectChatType, Select, DirectMessageData }: SideMenuProps ) => {
    return (
        <div className="course-options-container-scrollview">
            <div className="course-options-content-view">
                { DirectMessageData &&
                    // @ts-ignore
                    DirectMessageData.map( (messagOption, index) => {
                        return (
                            <div className="course-item" key={ index }>
                                <label className="course-label">{ `${ messagOption.first_name } ${ messagOption.last_name }` }</label>
                                <label className="time-from-last-message">{ '1h' }</label>
                                <label className="last-message">{'Actually let me take a look at what I put'}</label> {/* This will need a query before it's possible to place data here */}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}


interface MessageModel {
    firstname?: string;
    lastname?: string;
    from?: string; //
    to?: string; // Direct Message or To course chat
    isCourse?: boolean;
    ats?: string[];
    message?: string;
    images?: MediaModel[]; // A Set Of ID's that I can use to get images from the backend: if there are images They Are Shown Before the text
    date?: string;
}

interface MediaModel {
    imageFullName: string;
    name: string;
    extension: string;
}

interface ChatContainerProps {
    chatType: ChatType;
}

const ChatContainer = ({ chatType }: ChatContainerProps) => {
    const { userData, profileImageURL, updateOtherUserProfileImage } = useContext(DashboardContext);
    const [ chatMode, setChatMode ] = useState<ChatMode>(ChatMode.Default);

    const [ message , setMessage ] = useState<string>("")
    const thisSessionTime : string = "Today 12:53pm";
    let currentMessageRef = `_SCHOOLS/${userData._school.id}/_courses/${userData._courses[0]._id}/chat/${ '1' }`;
    async function sendMessage() {
        currentMessageRef = `_SCHOOLS/${userData._school.id}/_courses/${userData._courses[0]._id}/chat/${ '1' }`;
        await updateDoc(
            doc(
                db, 
                currentMessageRef
            ),
            {
                "messages": arrayUnion(
                    {
                        firstname: userData.first_name, lastname: userData.last_name,
                        from: userData.userID,
                        to: "",
                        isCourse: chatType === ChatType.CourseThread ? true : false,
                        ats:[],
                        message: message,
                        images: [],
                        date: `${new Date()}`,
                    }
                ),
            }
        );
    }

    const [ Messages, setMessages ] = useState<MessageModel[]>([]);

    function initializeListeners() {
        currentMessageRef = `_SCHOOLS/${userData._school.id}/_courses/${userData._courses[0]._id}/chat/${ '1' }`;
        // @ts-ignore
        onSnapshot(doc(db, currentMessageRef), (doc) => {
            const data = doc.data();
            let sessionMessagList = (data && data.messages && data.messages as MessageModel[]) ? data.messages as MessageModel[] : [];//doc as QuestionModel;
            setMessages( m => {
                return sessionMessagList;
            });
            console.log("Messages data: ", doc.data());
        });
    }


    useEffect(() => {
        //
        const chatHistory = document.querySelector("#chat-scrollview");
        chatHistory.scrollTop = chatHistory.scrollHeight;
        //
        initializeListeners();
    }, [])

    return (
        <div className="collab-chat-container">
            <div className="chat-content-messeges-container">
                <div className="chat-container-scrollview" id="chat-scrollview">
                    <div className="chat-container-contentview">
                        <div className="chat-time-session-container">
                            <label className="chat-session-time">{ thisSessionTime }</label>
                        </div>
                        {
                            Messages.map((message, index) => {
                                return  (
                                    <div 
                                        key={ index }
                                        className={`message-item ${ userData.userID === message.from ? 'this-user' : 'other-user' }`}
                                    >
                                        <div className="message-container">
                                            <label>{ message.message }</label>
                                        </div>
                                        <div className="message-sender-container">
                                            <img src={ userData.userID === message.from ? profileImageURL : updateOtherUserProfileImage(message.from) }/>
                                            <label className="user-name">{ `${ message.firstname } ${ message.lastname }` }</label>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="chat-content-and-controls">
                <ChatBox mode={ chatMode } setChatMode={ setChatMode } message={ message } setMessage={ setMessage }/>
                <SendAndMediaMode mode={ chatMode } setChatMode={ setChatMode } sendMessage={ sendMessage } message={ message } setMessage={ setMessage }/>
            </div>
        </div>
    );
}


interface MessageControlsProps {
    mode : ChatMode;
    setChatMode: React.Dispatch<React.SetStateAction<ChatMode>>;
    sendMessage : () => void;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

/*
    Image:
    <a href="https://www.flaticon.com/free-icons/picture" title="picture icons">Picture icons created by Freepik - Flaticon</a>
    <a href="https://www.flaticon.com/free-icons/picture" title="picture icons">Picture icons created by Freepik - Flaticon</a>

    Send:
    <a href="https://www.flaticon.com/free-icons/send" title="send icons">Send icons created by Bingge Liu - Flaticon</a>
    <a href="https://www.flaticon.com/free-icons/instagram-share" title="instagram share icons">Instagram share icons created by Freepik - Flaticon</a>
*/
const SendAndMediaMode = ( { mode, setChatMode, sendMessage, message }: MessageControlsProps ) => {
    
    return (
        <div className="send-and-media-mode-container">
            <div className={`media-mode${ mode === ChatMode.MediaMode ? ' active' : ''}`}
                onClick={() => {
                    setChatMode(mode => {
                        return mode === ChatMode.MediaMode ? ChatMode.Default : ChatMode.MediaMode;
                    })
                }}
            >
                <img src={ imageModeIcon }/>
                <label>Media Mode</label>
            </div>

            <div className="send-button" onClick={() => {
                sendMessage();
            }}>
                <img src={ sendIcon }/>
                <label className="send-message">Send</label>
            </div>

        </div>
    );
};

enum ChatMode {
    Default,
    MediaMode
}

interface ChatProps {
    mode : ChatMode;
    setChatMode: React.Dispatch<React.SetStateAction<ChatMode>>
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const ChatBox = ({ mode, setChatMode, message, setMessage }: ChatProps) => {
    return (
        <div className="chat-box-container">
            { (mode === ChatMode.Default) && <textarea className="chat-box"  placeholder="chat..."
                onChange={ e => {
                    setMessage(m => {
                        return e.target.value;
                    })
                }}
            />}
            { (mode === ChatMode.MediaMode) && <div className="chat-box" placeholder="chat..." contentEditable="true"></div> }
        </div>
    );
}



interface MessageRequestsAndUsersListProps {
    courses : CourseModel[]
}
const MessageRequestsAndUsersList = ({ courses }: MessageRequestsAndUsersListProps ) => {
    const { userData } = useContext(DashboardContext);

    return (
        <div className="message-requests-and-users-list">

            <div className="direct-message-requests-container">
                <div className="direct-message-requests">
                    <label className="direct-message-label message-requests-and-users-title">Direct Message Requests</label>
                    <div className="content-container"></div>
                </div>
            </div>

            <div className="peers-list-container">
                <div className="peers-list">
                    <label className="peers-label message-requests-and-users-title">Peers</label>
                    <div className="content-container">
                        { courses.length ?
                            courses.map(course => {
                                return (
                                    <>
                                        { course.students.map( (student, index) => {
                                            return (
                                                <>
                                                    { student.id !== userData.userID &&
                                                        <div className="peer-item" key={ index } >
                                                            <img className="user-profile-image" src={ test2 }/>
                                                            <label className="user-name">{`${student.firstName} ${student.lastName}`}</label>
                                                            <div className="add-user-buttom">
                                                                <div className="horizontal-plus"></div>
                                                                <div className="vertical-plus"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        })
                                        }
                                    </>
                                )
                            })
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}


/*

Collab Backend structure:
----------------------------

The messages are stored in the chat collection for the course if they are from the couse based messages. 

School ( collection ) -> 
    School ID ( Document ) -> 
        Course ( Collection ) -> 
            Course ID ( Document ) -> 
                Chat ( Collection ) -> 
                    [ Session Count : [1 -n ) ] ( Document )
                        
    Session Count:
    Is simply something 1, 2, 3 ... n
    Each session is created based on a the last time a message was added to the chat. If it has been over an hour then a new session is created. 
    
    The chat counter document keeps track of the last chat index. This index should keep up which session we should jump to when loading in messages
*/

function GetDocuments() {
    const chatIndex = 1;
    // const [ sessionMessages, setSessionMessages ] = [];

    // if (scrollToTop) {
    //     // show load more pop up
    // } if else (no_other_messages) {
    //     // Do nothing
    // }
}