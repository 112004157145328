import React from "react";
import './faq.css';

const FAQ = () => {
    return (
        <div className="faq-button">
            <h1>?</h1>
        </div>
    );
};

export default FAQ;